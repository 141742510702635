import { Container } from './styles';
import InputPrototypeProps from '../../../../interfaces/Components/FormElements/InputPrototypeProps';

export const InputPrototype: React.FC<InputPrototypeProps> = (
    {
        icon,
        handleClick,
        isFocused,
        isErrored,
        children
    }: InputPrototypeProps
) => {
    return (
        <Container isFocused={isFocused || false} isErrored={isErrored || false} onClick={handleClick} className='default-input'>
            {icon}
            {children}
        </Container>
    )
}