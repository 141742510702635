import styled from "styled-components";

export const Header = styled.div<{scrollTop: number}>`
    position: fixed;
    width: 100%;
    height: 60px;
    z-index: 999;

    margin-top: ${props => props.scrollTop > 60? "-60px" : "0px" };

    padding-right: 10%;

    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: #585858;

    display: flex;
    align-items: center;

    a {
        text-decoration: none;
        color: #FFF;
        font-weight: bold;

        .arrow-down {
            width: 215px;
            min-height: 60px;
            background: #004993;

            margin-right: 60px;
            text-align: center;
            padding-top: 20px;
            transition: all ease 0.2s;

            &:hover {
                background: #F6F7F9;
                color: #004993;
              
                span.arrow {
                  margin-right: 4px;
                }
              
                span.text {
                  margin-right: 4px;
                }
            }
        }
    }

    .strategy-name {
      color: #444242;
      padding: 29px 0;
      flex: 1.75;
    }

    .right-side {
        padding: 29px 0;
        flex: 1;
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .state-span {
      color: #444242;
      width: 160px;
    }

    .impressions-span {
      max-width: 200px;
      margin-left: 168px;
      color: #444242;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
`;

export const Container = styled.div`
  padding-bottom: 40px;
  
  & > div {
    max-width: 54.1875rem;
  }
  
  .processing-warning {
    background: #FEB344;
    box-shadow: 0px 4px 10px #D1D9E7;
    border-radius: 10px;
    font-size: 14px;
    color: #FFF;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    
    img {
      margin: 18px 15px 18px 18px;
    }
    
    p {
      margin: 20px 48px 20px 0;
      font-weight: 800;
    }
  }
  
  .last-update {
    p {
      color: #96A0A7;
      font-size: 14px;
    }
  }

  .audience-details-content {
    margin-top: -2px;
    display: flex;
  }
  
  .creative-statistics-details {
    display: flex;
    column-gap: 68px;
    margin-top: 20px;
  }
  
  .best-domains {
    margin-bottom: 135px;
  }

  .best-segments, .best-domains, .audience-details {
      margin-top: 60px;
      margin-bottom: 60px;
    
      #divisor {
        display: flex;
        align-items: center;
    
        #gray-line {
          flex: 5;
          height: 1px;
          background: #EBEBEB;
        }
    
        #blue-contrast {
          flex: 1;
          width: 140px;
          height: 6px;
          background: #004993;
        }
    }
  }
`;