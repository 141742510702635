import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useState, FocusEvent } from 'react';
import ptBR from 'date-fns/locale/pt-BR';
import "./date-picker.css";
import { Container } from './styles';

interface DatePickerProps {
    selectedDate: Date | null;
    setSelectedDate: (date: Date | null) => void;
    minimumDate?: Date;
    isErrored?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({ selectedDate, setSelectedDate, minimumDate, isErrored }: DatePickerProps ) => {
    registerLocale('ptBR', ptBR);

    const [hasError, setHasError] = useState(isErrored);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (date: Date | null) => {
        setSelectedDate(date);
        setIsFocused(false);
        setHasError(false);
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        if(event.target.value === '') setHasError(true);
    }


    return (
        <Container 
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            isFocused={isFocused} 
            isErrored={hasError || false}
        >
            <ReactDatePicker
                locale='ptBR' 
                selected={selectedDate} 
                onChange={handleChange}
                onBlur={handleBlur}
                dateFormat={'dd/MM/yyyy'}
                filterDate={minimumDate ? (date) => date.getTime() >= minimumDate.getTime(): undefined}
                showIcon={true}
                isClearable={false}
                children={
                    <div className='datepicker__instruction'><div className='datepicker__instruction__not-permited-dates'></div> datas não permitidas</div>
                }
            />
        </Container>
    )
}

export default DatePicker;