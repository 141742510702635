import React, { useEffect, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface IScrollToTopProps extends RouteComponentProps<any> {
    children: ReactNode
}

const ScrollToTop: React.FC<IScrollToTopProps> = ({ history, children }) => {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return (
        <>
            {children}
        </>
    );
}

export default withRouter(ScrollToTop);
