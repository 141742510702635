import React from "react";
import { Switch, HashRouter } from "react-router-dom";

import ScrollToTop from "../components/Generic/ScrollToTop/ScrollToTop";

import NewCampaign from "../pages/NewCampaign";
import Authenticate from "../pages/Authenticate";
import CampaignStatus from "../pages/ListCampaigns";
import CampaignDetails from "../pages/CampaignDetails";
import UserFirstAccess from "../pages/UserFirstAccess";
import UploadCreatives from "../pages/Creatives/UploadCreative";
import ReportDataIngestion from "../pages/ReportDataIngestion";

import PublicRoute from "../services/auth/PublicRoute";
import PrivateRoute from "../services/auth/PrivateRoute";


const Routes: React.FC = () => (
    <HashRouter>
        <ScrollToTop>
            <Switch>
                {/* private routes */}
                <PrivateRoute path="/create-campaigns" component={NewCampaign} />
                <PrivateRoute path="/list-campaigns" component={CampaignStatus} />
                <PrivateRoute path="/campaign-details/:id" component={CampaignDetails} />
                <PrivateRoute path="/upload-creatives/" component={UploadCreatives} />
                <PrivateRoute path="/ingest-report-data/" component={ReportDataIngestion} />

                {/* public routes */}
                <PublicRoute path="/first-access/:token" component={UserFirstAccess} />
                <PublicRoute path="/" component={Authenticate} />
            </Switch>
        </ScrollToTop>
    </HashRouter>
)

export default Routes;
