import styled from "styled-components";

export const Container = styled.div`
    padding: 30px 0 60px;
    max-width: 45.4375rem;

    h2 {
        font-weight: bold;
        font-size: 18px;
        color: #444242;

        margin: 0 0 20px 60px;
    }

    #divisor {
        display: flex;
        align-items: center;
        #gray-line {
            flex: 5;
            height: 1px;
            background: #EBEBEB;
        }

        #blue-contrast {
            flex: 1;
            width: 140px;
            height: 6px;
            background: #004993;
        }
    }

    .content {
        padding: 30px 60px 0;

        .form-input-block {
            span {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #444242;
    
                margin-bottom: 8px;
                display: block;
            }

            #url-description {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;

                margin-top: 8px;
                color: #96A0A7;
            }
    
            input {
                width: 100%;
            }

            & + .form-input-block {
                margin-top: 30px;
            }
        }

        #date-input-block {
            display: flex;

            #duration-time {
                margin-left: 60px;
            }
        }
    }
`;