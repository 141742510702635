import styled from "styled-components";

export const Container = styled.div<{ toggled: boolean }>`
  position: absolute;

  display: ${(props) => (props.toggled ? "block" : "none")};

  margin-top: 10px;
  margin-left: -900%;
  border-radius: 11px;

  z-index: 99999;
`;

export const Header = styled.div`
  padding-top: 10px;
  padding-left: 15px;

  color: #96a0a7;
  font-weight: 300;
  font-family: "Mulish";
  font-size: 10px;
`;

export const Main = styled.div<{ showScroll: boolean }>`
  ${(props) =>
    props.showScroll &&
    `
        max-height: 166px;
        overflow-y: scroll;
    `};
`;

export const Footer = styled.div`
  border-top: 1px solid #ebebeb;
  padding: 7px 6px 7px 6px;

  font-family: "Mulish";
  font-weight: 400;
  font-size: 11px;
  color: #96a0a7;

  div {
    border-radius: 5px;
    padding: 4px 55px 4px 9px;

    &:hover {
      background-color: #f1f1f1;
      cursor: pointer;
    }
  }
`;
