import styled from "styled-components";


export const GeneralContainer = styled.div<{paddingLeft: string}>`
  width: 299px;
  height: 121px;
  border-left: 1px solid #EBEBEB;

  .detail-title {
    margin: 0 auto;
    width: 100%;
    padding-top: 16px;
    height: 43px;
    padding-left: ${props => props.paddingLeft};

    img {
      padding-right: 8px;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      color: #444242;
    }
  }

  .detail-value {
    margin: 0 auto;
    width: 100%;

    h4 {
      color: #444242;
      font-weight: 700;
      font-size: 34px;
      line-height: 43px;
      margin-top: 5px;
      width: 100%;
      padding-left: ${props => props.paddingLeft};
    }
  }
`;
