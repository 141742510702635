import styled, { css } from "styled-components";

export const Container = styled.div<{ isFocused: boolean, isErrored: boolean }>`
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 47px;
    position: relative;

    border: 1px solid #BFBFBF;

    ${props => props.isErrored && css`
        border: 1px solid #FF5656 !important;
    `}

    ${props => props.isFocused && css`
        border: 1px solid #256EF3 !important;
    `}

    &:hover {
        cursor: text;
    }

    img, svg {
        width: 25px;
        height: 25px;

        margin-right: 16px;
        color:  #96A0A7;

        &:hover {
            cursor: pointer;
        }
    }
`;