import React, {useState, useEffect} from "react";
import {useRouteMatch, useHistory} from 'react-router-dom';
import {
    BaseContainer, ErrorBox, ErrorBoxContainer,
    LeftImageContainer,
    LeftImageContent,
    RightPasswordContainer,
    RightPasswordContent,
    RightPasswordForm,
    NameInputsContainer,
    RightPasswordInputsContainer,
    RightPasswordRequirementsContainer,
    SaveButton, SuccessBox
} from "./styles";
import leftImage from '../../assets/first-access/left-image.png';
import ecbrAdsLogo from '../../assets/ECBR_Ads.png';
import showPassIcon from '../../assets/first-access/show-pass-icon.png';
import hidePassIcon from '../../assets/first-access/hide-pass-icon.png';
import {UsersHttpClient} from "../../services/api/platform-api/users-http-client";

interface EmailToken {
    token: string;
}

const UserFirstAccess: React.FC = () => {
    const { params } = useRouteMatch<EmailToken>();
    const history = useHistory();

    useEffect(() => {
        new UsersHttpClient().validateUserFirstPasswordToken(params.token)
            .then((response:any) => {
                if(!response.data.status)
                    history.push('/');
            })
            .catch(() => {
                history.push('/');
            });
    }, []);


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const [isPassWithUpperLetter, setIsPassWithUpperLetter]= useState(false);
    const [isPassWithSymbol, setIsPassWithSymbol]= useState(false);
    const [isPassWithMinLength, setIsPassWithMinLength]= useState(false);
    const [isPasswordWithError, setIsPasswordWithError] = useState(false);
    const [isConfirmPassWithError, setIsConfirmPassWithError] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [isShownPassword, setIsShownPassword] = useState(false);
    const [isShownConfirmPassword, setIsShownConfirmPassword] = useState(false);


    const handleNameInputChange = (
        value: string, 
        setNameInput: React.Dispatch<React.SetStateAction<string>>
    ) => {
        setNameInput(value.trim());
    }

    const handleOnChangePassword = (value: string) => {
        validatePasswordOnChange(value);
        setPassword(value);
    }

    const handleOnChangeConfirmPassword = (value: string) => {
        setConfirmPassword(value);
    }
    const validatePasswordOnChange = (value: string) => {
        setIsPassWithUpperLetter(/[A-Z]/.test(value));
        setIsPassWithSymbol(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value));
        setIsPassWithMinLength(value.length >= 8);
    }

    const isConfirmPasswordEqualsPassword = () => {
        const isPassEqual = confirmPassword === password;
        setIsConfirmPassWithError(!isPassEqual);

        return isPassEqual;
    }

    const handleShowPasswordClick = () => {
        setIsShownPassword(!isShownPassword);
    }

    const handleShowConfirmPasswordClick = () => {
        setIsShownConfirmPassword(!isShownConfirmPassword);
    }

    const hasErrorOnSubmit = () => {
        let hasError = false;
        let errorText = '';

        if(!isConfirmPasswordEqualsPassword()) {
            errorText = 'As duas senhas não coincidem.';
            hasError = true;
        } else if (!isPassWithMinLength || !isPassWithSymbol || !isPassWithUpperLetter) {
            errorText = 'Ao menos um requisito de senha não foi cumprido.';
            setIsConfirmPassWithError(true);
            setIsPasswordWithError(true);
            hasError = true;
        } else if (firstName.length < 1 || lastName.length < 1) {
            errorText = 'Nome ou sobrenome não preenchido.';
            hasError = true;
        }

        setHasSubmitError(hasError);
        if(hasError) {
            setError(errorText);
        } else {
            setIsConfirmPassWithError(false);
            setIsPasswordWithError(false);
        }

        return hasError;
    }

    const handleSubmit = () => {
        if(hasErrorOnSubmit())
            return;

        new UsersHttpClient().defineUserFirstPassword(params.token, firstName, lastName, password, confirmPassword)
            .then((response:any) => {
                if (!response.data.status) {
                    setError('Algo inesperado aconteceu. Tente novamente mais tarde!');
                    setHasSubmitError(true);
                } else {
                    setSuccess(true);
                }
            })
            .catch(() => {
                setError('Algo inesperado aconteceu. Tente novamente mais tarde!')
                setHasSubmitError(true)
            });
    }

    return (
        <BaseContainer>
            <LeftImageContainer>
                <LeftImageContent>
                    <img src={leftImage} />
                    <h1>Bem-vindo ao<br />E-commerce Brasil Ads!</h1>
                    <p>Sua nova plataforma de mídia para aproveitar todo<br />o potencial da audiência do ECBR para o<br />
                        crescimento do seu negócio.</p>
                </LeftImageContent>
            </LeftImageContainer>
            <RightPasswordContainer>
                <RightPasswordForm>
                    <RightPasswordContent>
                        <img src={ecbrAdsLogo} />
                        <h4>Configure sua senha</h4>
                        <p>Falta só mais essa etapa para acessar <br />sua plataforma.</p>
                        <div style={{display: "flex"}}>
                            <NameInputsContainer style={{marginLeft: "41px"}}>
                                <label>Nome</label>
                                <input type="text" onChange={(e) => handleNameInputChange(e.target.value, setFirstName)} />
                            </NameInputsContainer>
                            <NameInputsContainer>
                                <label>Sobrenome</label>
                                <input type="text" onChange={(e) => handleNameInputChange(e.target.value, setLastName)} />
                            </NameInputsContainer>
                        </div>
                        <RightPasswordInputsContainer>
                            <img src={isShownPassword ? showPassIcon : hidePassIcon} onClick={() => handleShowPasswordClick()}/>
                            <label>Primeira senha</label>
                            <input type={isShownPassword ? "text" : "password"}
                                   className={`${isPasswordWithError ? 'input-error' : ''}${success ? 'success-input' : ''} top-input`}
                                   onChange={(e) => handleOnChangePassword(e.target.value)} />
                            <img src={isShownConfirmPassword ? showPassIcon : hidePassIcon} onClick={() => handleShowConfirmPasswordClick()}/>
                            <label>Confirmar senha</label>
                            <input type={isShownConfirmPassword ? "text" : "password"}
                                   className={`${isPasswordWithError ? 'input-error' : ''}${success ? 'success-input' : ''}`}
                                   onChange={(e) => handleOnChangeConfirmPassword(e.target.value)} />
                        </RightPasswordInputsContainer>
                        <RightPasswordRequirementsContainer>
                            <span className={`${isPassWithUpperLetter ? 'pass-req-success' : ''}`}>&#10003; 1 letra maiúscula</span>
                            <span className={`${isPassWithSymbol ? 'pass-req-success' : ''}`}>&#10003; 1 caractere especial</span>
                            <span className={`${isPassWithMinLength ? 'pass-req-success' : ''}`}>&#10003; Ao menos 8 digitos</span>
                        </RightPasswordRequirementsContainer>
                        <SaveButton>
                            <button disabled={success} onClick={() => handleSubmit()}>
                                Salvar
                            </button>
                        </SaveButton>
                        <ErrorBoxContainer>
                            {hasSubmitError ?
                                <ErrorBox>
                                    {error}
                                </ErrorBox>
                                : null
                            }
                            {success ?
                                    <SuccessBox>
                                        <span>Sua senha foi criada com sucesso! <br /><a href="/">Clique aqui para fazer seu login</a></span>
                                    </SuccessBox>
                                : null
                            }
                        </ErrorBoxContainer>
                    </RightPasswordContent>
                </RightPasswordForm>
            </RightPasswordContainer>
        </BaseContainer>
    )
}

export default UserFirstAccess;