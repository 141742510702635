import React, { useEffect, useState } from "react";
import NumberFormat from 'react-number-format';
import { BsFillCircleFill } from "react-icons/bs";
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import MainHeader from "../../components/Generic/MainHeader";
import AudienceDetail from "../../components/Campaigns/CampaignDetails/AudienceDetail";
import CampaignCreatives from "../../components/Campaigns/CampaignDetails/CampaignCreatives";
import InvestmentDetail from "../../components/Campaigns/Generic/InvestmentDetail";
import { CampaignsHttpClient } from "../../services/api/platform-api/campaigns-http-client";
import { Header, Container } from './styles';
import {CampaignDetailData} from "../../interfaces/Campaigns/CampaignDetails/CampaignDetailData";
import {CampaignId} from "../../interfaces/Campaigns/CampaignDetails/CampaignId";
import formatStatusString from "../../services/utils/formatStatusString";
import getStatusColor from "../../services/utils/getStatusColor";
import {CampaignReportHttpClient} from "../../services/api/data-api/campaign-report-http-client";
import warningIcon from '../../assets/warning-icon.png';
import userAudienceIcon from '../../assets/audience-details/audience-user-icon.png';
import newUsersAudienceIcon from '../../assets/audience-details/audience-new-users-icon.png';
import sessionsAudienceIcon from '../../assets/audience-details/audience-sessions-icon.png';
import frequencyCreativeIcon from '../../assets/creative-details/frequency-creative-icon.png';
import reachCreativeIcon from '../../assets/creative-details/reach-creative-icon.png';
import viewabilityCreativeIcon from '../../assets/creative-details/viewability-creative-icon.png';
import lastUpdateIcon from '../../assets/audience-details/reload-icon.png'
import AudienceStatisticsDetail from "../../components/Campaigns/CampaignDetails/AudienceStatisticsDetail";
import PercentageTableList from "../../components/PercentageTableList";
import {PercentageTableListObject} from "../../components/PercentageTableList/interface/PercentageTableListObject";
import parseBestSegmentsToPercentObject from "../../services/CampaignReport/parseBestSegmentsToPercentObject";
import parseBestDomainsToPercentObject from "../../services/CampaignReport/parseBestDomainsToPercentObject";
import attachCreativeImpsToCreativeList from "../../services/CampaignReport/attachCreativeImpsToCreativeList";
import CreativeStatisticsDetail from "../../components/Campaigns/CampaignDetails/CampaignCreativesStatisticsDetail";
import dateFormat from 'date-fns/format';


const CampaignDetails: React.FC = () =>{
    const [campaign, setCampaign] = useState<CampaignDetailData>({} as CampaignDetailData);
    const [bestSegments, setBestSegments] = useState<PercentageTableListObject[]>({} as PercentageTableListObject[]);
    const [bestDomains, setBestDomains] = useState<PercentageTableListObject[]>({} as PercentageTableListObject[])
    const [scrollTop, setScrollTop] = useState(0);


    const history = useHistory();
    const { params } = useRouteMatch<CampaignId>();

    useEffect(() => {
        new CampaignsHttpClient().getCampaignInfo(params.id)
            .then(campaignData => {
                let campaignState = campaignData.data.data;
                new CampaignReportHttpClient().getCampaignViewFullReport([Number(params.id)]).then(function (reportData) {
                    campaignState.report = reportData.data[0];

                    campaignState.creatives = attachCreativeImpsToCreativeList(campaignState.creatives,
                        campaignState.report.creative_performance_statistics_report.impressions_by_creative);
                    setCampaign(campaignState);

                    setBestSegments(parseBestSegmentsToPercentObject(campaignState.report.campaign_best_segments_report
                        .impressions_by_segment, campaignState.report.campaign_total_impressions_report.total_impressions));
                    setBestDomains(parseBestDomainsToPercentObject(campaignState.report.campaign_best_domains_report
                        .impressions_by_domain, campaignState.report.campaign_total_impressions_report.total_impressions));
                });
            })
            .catch(() => history.push('/list-campaigns'));

    }, [history, params.id]);


    useEffect(() => {
        const onScroll = () => setScrollTop(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>
            <MainHeader />
            <Header className='shadow-header' scrollTop={scrollTop}>
                <Link to='/list-campaigns'>
                    <div className="arrow-down"><span className='arrow'>{'<'}</span> <span className='text'>{'Voltar'}</span></div>
                </Link>
                <div className="strategy-name">
                     {campaign.name}
                </div>
                <div className='right-side'>
                    <span className="state-span">
                        <BsFillCircleFill style={{marginRight: "10px"}} size={10} fill={getStatusColor(campaign.state)} /> {formatStatusString(campaign.state)}
                    </span>
                    <span className="impressions-span">
                        <NumberFormat
                            style={{paddingRight: "10px"}}
                            displayType="text"
                            thousandSeparator={"."} 
                            decimalSeparator={","}
                            value={campaign.report===undefined? 0 : campaign.report?.campaign_total_impressions_report.total_impressions}
                        />
                        impressões
                    </span>
                </div>
            </Header>

            <Container className='centered-container'>
                <div className='last-update'>
                    <p>
                        <img src={lastUpdateIcon} />
                        <span> última atualização geral: {
                            campaign.report !==undefined && campaign.report.last_update !== undefined
                                && campaign.report.last_update !== null
                                    ? dateFormat(new Date(campaign.report.last_update), 'dd/MM')
                                    : '-'
                            }
                        </span>
                    </p>
                </div>

                {campaign.state === 'processing' ? <div className="processing-warning">
                    <img src={warningIcon} />
                    <p>Sua campanha está em processamento e receberá todos os dados de performance assim que as etapas de
                        instalação forem concluídas e a campanha estiver devidamente ativa!</p>
                </div> : <div className='creative-statistics-details'>
                    <CreativeStatisticsDetail 
                        imageSrc={reachCreativeIcon} 
                        dataTitle={'Alcance'}
                        dataNumber={campaign.report===undefined ? 
                            0 : campaign.report.campaign_performance_statistic_report.reach}
                        percentage={false} 
                    />
                    <CreativeStatisticsDetail 
                        imageSrc={frequencyCreativeIcon} 
                        dataTitle={'Frequência'}
                        dataNumber={campaign.report===undefined ? 
                            0 : campaign.report.campaign_performance_statistic_report.frequency
                        }
                        percentage={false} 
                    />
                    <CreativeStatisticsDetail 
                        imageSrc={viewabilityCreativeIcon} 
                        dataTitle={'Visibilidade'}
                        dataNumber={campaign.report===undefined ? 
                            0 : campaign.report.campaign_performance_statistic_report.viewability * 100
                        } 
                        percentage={true} 
                    />
                </div>}

                <CampaignCreatives creatives={campaign.creatives} displayImps={campaign.state !== 'processing'} />

                <AudienceDetail categories={campaign.segment?.categories} keywords={campaign.segment?.keywords} />

                {campaign.state !== 'processing' ? <div className='best-segments'>
                    <div className='card-shadow'>
                        <div className="card-shadow-title">
                            <h2>Melhores Públicos</h2>
                        </div>
                        <div id="divisor">
                            <div id="blue-contrast"></div>
                            <div id="gray-line"></div>
                        </div>
                        <div className='default-table'>
                           <PercentageTableList objects={bestSegments===undefined ? [] : bestSegments}
                                                titles={['Categorias/keywords', 'Relevância']}/>
                        </div>
                    </div>
                </div> : ''}

                {campaign.state !== 'processing' ? <div className='best-domains'>
                    <div className='card-shadow'>
                        <div className="card-shadow-title">
                            <h2>Melhores Canais</h2>
                        </div>
                        <div id="divisor">
                            <div id="blue-contrast"></div>
                            <div id="gray-line"></div>
                        </div>
                        <div className='default-table'>
                            <PercentageTableList objects={bestDomains===undefined ? [] : bestDomains}
                                                 titles={['Sites', 'Relevância']}/>
                        </div>
                    </div>
                </div> : ''}

                {campaign.state !== 'processing' ? <div className='audience-details'>
                    <div className="card-shadow">
                        <div className="card-shadow-title">
                            <h2>Engajamento</h2>
                        </div>
                        <div id="divisor">
                            <div id="blue-contrast"></div>
                            <div id="gray-line"></div>
                        </div>
                        <div className="audience-details-content">
                            <AudienceStatisticsDetail 
                                imageSrc={userAudienceIcon} 
                                audienceTitle={'Usuários totais'}
                                audienceNumber={
                                    campaign.report===undefined ? 0 : campaign.report.campaign_ds_report.users
                                }
                                paddingLeft="64px"
                            />
                            <AudienceStatisticsDetail 
                                imageSrc={newUsersAudienceIcon} 
                                audienceTitle={'Novos usuários'}
                                audienceNumber={
                                    campaign.report===undefined ? 0 : campaign.report.campaign_ds_report.new_users
                                }
                                paddingLeft="30px"
                            />
                            <AudienceStatisticsDetail 
                                imageSrc={sessionsAudienceIcon} 
                                audienceTitle={'Sessões totais'}
                                audienceNumber={
                                    campaign.report===undefined ? 0 : campaign.report.campaign_ds_report.sessions
                                } 
                                paddingLeft="30px"
                            />
                        </div>
                    </div>
                </div> : ''}

                <InvestmentDetail viewOnlyCpmMax={campaign.segment?.max_cpm} viewOnlyBudget={campaign.segment?.budget} />
            </Container>
        </>
    );
}

export default CampaignDetails;
