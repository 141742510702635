import {DataAPIHttpClient} from "./data-api-http-client";
import qs from 'qs';

export class CampaignReportHttpClient extends DataAPIHttpClient {
    public getTotalImpressionsByCampaign = (idOfCampaigns: number[]) => this.instance.get('/report/campaigns/report_builder', {
        params: {
            type: 'CPG_TOTAL_IMPS',
            campaign_id: idOfCampaigns
        },
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
    })

    public getCampaignViewFullReport = (idOfCampaigns: number[]) => this.instance.get('/report/campaigns/report_builder', {
        params: {
            campaign_id: idOfCampaigns
        },
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
    })

    public postReportData = (formData: FormData) => {
        return this.instance.post(
            '/report/campaigns/ingest_report_data', 
            formData,
            {
                headers: {
                    'Content-type': 'multipart/form-data',
                }
            }
        );
    };
}