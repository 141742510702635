import styled from "styled-components";

export const Container = styled.div`
  position: absolute;

  margin-left: 90%;
`;

export const ButtonContainer = styled.div``;

export const ToggleMenuButton = styled.button<{ toggled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  background: ${(props) => (props.toggled ? "#004993" : "#D3D3D3")};
  border: none;
  border-radius: 3px;

  font-family: "Mulish";
  font-weight: 400;
  font-size: 15px;
  color: white;

  &:hover {
    background: #004993;
    outline: 4px solid #dcecfd;
    outline-offset: 0.5px;
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

export const ButtonToolTip = styled.div<{
  toggleToolTip: boolean;
  toggled: boolean;
}>`
  display: ${(props) =>
    props.toggleToolTip && !props.toggled ? "flex" : "none"};
  justify-content: center;
  align-items: center;

  position: fixed;

  width: 155px;
  height: 28px;
  padding: 5px 12px 5px 12px;

  margin-top: 4px;
  margin-left: -120px;

  background: #444242;
  border-radius: 5px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  color: #ffffff;

  z-index: 99999;
`;
