import styled, { css } from "styled-components";

interface ContainerProps {
    showCard: boolean;
}

interface ControllerProps {
    selectedOption: number;
}

export const Container = styled.div<ContainerProps>`
    width: 18.125rem;

    background: #FFFFFF;
    box-shadow: 0px 0px 12px #D1D9E7;
    border-radius: 10px;

    position: absolute;
    top: 100%;
    left: 100%;

    display: none;

    ${props => props.showCard && css`
        display: block;
    `};
`;

export const Controller = styled.div<ControllerProps>`
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0 1.75rem;
        overflow: hidden;
        
        display: flex;
        align-items: center;

        border-bottom: 1px solid #EBEBEB;
        
        li {
            padding: .5625rem 0;
            cursor: pointer;

            font-size: .875rem;
            line-height: 1.125rem;
            color: #004993;

            div {
                padding: .375rem .75rem;
                border-radius: 4px;

                &:hover {
                    background: #F0F2F5;
                }
            }

            & + li {
                margin-left: 2.25rem;
            }
        }

        ${props => props.selectedOption !== undefined && css`
            li:nth-of-type(${props.selectedOption}) {
                border-bottom: 1px solid #006DAC;
            }
        `}

        /* li:nth-of-type(1) {
            border-bottom: 1px solid #006DAC;
        } */
    }
`;