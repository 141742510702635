import React, { useCallback, useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";

import MainHeader from '../../components/Generic/MainHeader';
import DisplayCampaignsInfo from '../../components/Campaigns/ListCampaigns';
import StartingSteps from '../../components/Campaigns/ListCampaigns/StartingSteps';

import { CampaignsHttpClient } from "../../services/api/platform-api/campaigns-http-client";
import { CampaignReportHttpClient } from "../../services/api/data-api/campaign-report-http-client";

import { Container } from './styles';

import CreateCampaignDisplay from '../../components/Campaigns/ListCampaigns/CreateCampaignDisplay';
import { CampaignDetailData } from '../../interfaces/Campaigns/CampaignDetails/CampaignDetailData';
import SkeletonPage from '../../components/Generic/SkeletonPage';


const ListCampaigns: React.FC = () => {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState<CampaignDetailData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCampaignsAndRedirectIfNoCampaign = useCallback(() => {
      new CampaignsHttpClient().getCampaignsList()
          .then(function (response) {
              let campaignList = response.data.data;

              if (campaignList.length === 0) {
                setIsLoading(false);
                return;
              } 

              let campaignsId: number[] = campaignList.map((campaignData:any) => campaignData.id);

              const stateResponse:any = [];

              new CampaignReportHttpClient().getTotalImpressionsByCampaign(campaignsId).then(function (response) {
                  campaignList.forEach((campaignItem:any) => {
                      let campaignReportData = response.data.find((item:any) => item.campaign_id === campaignItem.id);


                      stateResponse.push({
                        id: campaignItem.id,
                        report: campaignReportData,
                        name: campaignItem.name,
                        state: campaignItem.state,
                        campaign_pi_signature: campaignItem.campaign_pi_signature,
                        creatives: campaignItem.creatives
                      });
                  })

                  setCampaigns(stateResponse);
              });
              
              setIsLoading(false);
          })
          .catch(function () {
            setIsLoading(false);
          });
  }, [setCampaigns, history]);

  useEffect(() => getCampaignsAndRedirectIfNoCampaign(), [setCampaigns, getCampaignsAndRedirectIfNoCampaign]);

  const someProcessingCampaign = (campaigns.some(campaign => campaign.state === 'processing'));
  const someCampaignMissingPiSignature = (campaigns.some(campaign => campaign.campaign_pi_signature === null));
  const someCampaignMissingCreatives = (campaigns.some(campaign => campaign.creatives.length === 0));

  return (
    <>
      {isLoading ?
        (
          <SkeletonPage />
        )
        :
        (
          <>
            {campaigns.length > 0 ? 
              (
                <>
                  <MainHeader title='Central de Campanhas' />
      
                  <Container className='centered-container'>
                    <DisplayCampaignsInfo campaigns={campaigns} />
      
                    {someProcessingCampaign && (
                      <StartingSteps 
                        hasMissingSignature={someCampaignMissingPiSignature}
                        hasMissingCreatives={someCampaignMissingCreatives}
                        campaigns={campaigns}
                      />
                    )}
      
                  </Container>
                </>
              )
              : 
              (
                <>
                  <MainHeader title='Central de Campanhas' />
                  <Container className='centered-container'>
                    <CreateCampaignDisplay />
                  </Container>
                </>
              )
            }
          </>
        )
        
      }
    </>
  );
}

export default ListCampaigns;