import styled from "styled-components";

export const Container = styled.div`
    margin: 60px auto 0;
    max-width: 867px;
    padding-bottom: 60px;

    .title-div {
        height: 75px;
        border-bottom: 1px solid #EBEBEB;
    }

    h2 {
        font-weight: bold;
        font-size: 16px;
        color: #006DAC;
        height: 75px;
        max-width: 230px;
        border-bottom: 1px solid #006DAC;
        padding: 28px 70px;

    }
`;

export const CreateCampaignData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;

    p {
        font-size: 14px;
        line-height: 18px;
        color: #444242;
    }

    button {
        padding: 12px 31px;
        border: none;
        background: #004993;
        border-radius: 4px;

        color: #FFF;
        font-weight: 700;
        font-size: 19px;

        &:hover {
            cursor: pointer;
        }
    }
`;