import { useState } from 'react';
import RequestCreatives from './RequestCreatives';
import { Container, Controller } from './styles';
import AttachCreatives from './AttachCreatives';
import OutsideClickToggleContainer from '../../../Generic/OutsideClickToggleContainer';
import Toast from '../../../Generic/Toast';
import { ToastItemProps } from '../../../../interfaces/Toast/IToastInterfaces';

interface UploadCreativesCardProps {
    showCard: boolean;
    setShowCard: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdloadCreativesCard: React.FC<UploadCreativesCardProps> = ({ showCard, setShowCard } : UploadCreativesCardProps) => {
    const [selectedOption, setSelectedOption] = useState(1);
    const [toastList, setToastList] = useState<ToastItemProps[]>([]);

    const renderSelectedOption = () => {
        const options = [<AttachCreatives setToastList={setToastList} />, <RequestCreatives setToastList={setToastList}  />]
        return options[selectedOption - 1];
    }

    return (
        <>
            <Container showCard={showCard}>
                <OutsideClickToggleContainer show={showCard} setShow={setShowCard}>
                    <Controller selectedOption={selectedOption}>
                        <ul>
                            <li onClick={() => setSelectedOption(1)}>
                                <div>
                                    Anexar
                                </div>
                            </li>
                            <li onClick={() => setSelectedOption(2)}>
                                <div>
                                    Pedir anúncios
                                </div>
                            </li>
                        </ul>
                    </Controller>
                    {renderSelectedOption()}
                </OutsideClickToggleContainer>
            </Container>

            <Toast position="bottom-left" toastList={toastList} />
        </>
    );
}

export default UpdloadCreativesCard;