import styled, { css } from "styled-components";

interface ContainerProps {
    position: "top-left" | "top-right" | "bottom-left" | "bottom-right";
}

interface ToastContainerProps {
    type: "success" | "error";
}

export const Container = styled.div<ContainerProps>`
    position: fixed;
    z-index: 999;

    ${props => props.position === "top-left" && `
        top: 20px;
        left: 20px;
        transition: transform .6s ease-in;
        animation: toast-in-left .7s;
    `}

    ${props => props.position === "top-right" && `
        top: 20px;
        right: 20px;
        transition: transform .6s ease-in-out;
        animation: toast-in-right .7s;
    `}

    ${props => props.position === "bottom-left" && `
        bottom: 20px;
        left: 20px;
        transition: transform .6s ease-in;
        animation: toast-in-left .7s;
    `}

    ${props => props.position === "bottom-right" && `
        bottom: 20px;
        right: 20px;
        transition: transform .6s ease-in-out;
        animation: toast-in-right .7s;
    `}
`;

export const ToastContainer = styled.div<ToastContainerProps>`
    opacity: 1;
    background: #FF5656;
    padding: 20px;

    & + & {
        margin-top: 15px;
    }

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    position: relative;
    max-width: 359px;

    z-index: 999;

    button {
        color: #C4C4C4;
        background: none;
        border: none;

        position: absolute;
        top: 11px;
        right: 11px;

        font-size: 11px;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
    }

    ${props => props.type === "success" && css`
        background: #1BCC62;
    `}

    ${props => props.type === "error" && css`
        background: #FF5656;
    `}
`;