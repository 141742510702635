import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

//#region Alert
export const AlertContainer = styled.div`
  margin-top: 15%;

  width: 350px;
  height: 200px;
`;

export const AlertHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;

  width: 100%;
  height: 25%;

  border-bottom: solid 1px #ebebeb;

  font-family: "Mulish";
  font-weight: bolder;
  font-size: 20px;

  color: red;
`;

export const AlertContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  width: 100%;
  height: 50%;

  font-family: "Mulish";
  font-size: 15px;
  text-align: center;
`;

export const AlertFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;

  border-top: solid 1px #ebebeb;

  width: 100%;
  height: 25%;
`;

export const AlertButton = styled.button`
  padding: 5px;

  width: 60px;

  font-family: "Mulish";
  font-size: 15px;
  text-align: center;

  color: white;

  background: rgb(0, 73, 147);
  border-radius: 5px;
  border: none;

  &:hover {
    cursor: pointer;
    background: #f6f7f9;
    border: 1px solid rgb(0, 73, 147);
    color: #004993;
  }
`;
//#endregion

//#region Upload
export const UploadContainer = styled.div`
  margin-top: 15%;

  width: 350px;
  height: 200px;
`;

export const UploadHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;

  width: 100%;
  height: 25%;

  border-bottom: solid 1px #ebebeb;

  font-family: "Mulish";
  font-weight: bolder;
  font-size: 20px;
`;

export const UploadContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  width: 100%;
  height: 50%;

  font-family: "Mulish";
  font-size: 15px;
  text-align: center;
`;

export const UploadFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;

  border-top: solid 1px #ebebeb;

  width: 100%;
  height: 25%;
`;

export const UploadButton = styled.button`
  padding: 5px;

  width: 60px;

  font-family: "Mulish";
  font-size: 15px;
  text-align: center;

  color: white;

  background: rgb(0, 73, 147);
  border-radius: 5px;
  border: none;

  &:hover {
    cursor: pointer;
    background: #f6f7f9;
    border: 1px solid rgb(0, 73, 147);
    color: #004993;
  }
`;
//#endregion
