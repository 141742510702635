import { Container } from "./styles";

interface InfoSliderProps {
    src: string;
    title: string;
    description: string;
}

export default function InfoSlider({ src, title, description }: InfoSliderProps) {
    return (
        <Container>
            <div className="img-container">
                <img src={src} alt={title} />
            </div>
            <span className="title">{title}</span>
            <span className="description">{description}</span>
        </Container>
    );
}