import React, { useState } from "react";

import RoleManagementMenu from "../RoleManagementMenu";

import {
    Container,
    UserIcon,
    UserInfoContainer,
    RoleInfoContainer,
    StyledExtendIcon
} from "./styles";


interface UserItemProps {
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    role?: string,
    removeUserHandler: (userId?: number) => void
};

function UserItem(props: UserItemProps) {
    const [roleMenuToggled, setRoleMenuToggled] = useState(false);

    const capitalizeString = (value?: string) => {
        if (!value) {
            return "";
        }

        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    const getRole = (value?: string) => {
        if (!value) {
            console.error("EMPTY ROLE");
            return;
        }

        switch (value) {
            case "administrator":
                return "Administrador";
            default:
                console.error("UNKNOWN ROLE");
        }
    };

    const hideMenu = (e: Event) => {
        if (!roleMenuToggled)
            return;

        setRoleMenuToggled(false);
    };

    return (
        <Container>
            <UserIcon>
                {props.email?.charAt(0).toUpperCase()}
            </UserIcon>
            <UserInfoContainer>
                <div className="name-container">
                    {`${capitalizeString(props.firstName)} ${capitalizeString(props.lastName)}`}
                </div>
                <div className="email-container">
                    {props.email}
                </div>
            </UserInfoContainer>
            <RoleInfoContainer onClick={() => { setRoleMenuToggled(prevState => !prevState) }}>
                {getRole(props.role)}
                <StyledExtendIcon />
            </RoleInfoContainer>
            <RoleManagementMenu
                toggled={roleMenuToggled}
                userId={props.id}
                handleClickOutside={hideMenu}
                removeUserHandler={props.removeUserHandler}
                untoggledHandler={() => setRoleMenuToggled(false)}
            />
        </Container>
    );
}

export default UserItem;
