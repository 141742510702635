import styled, {css} from "styled-components";

interface InputInfo{
    isFocused: boolean;
    isErrored: boolean;
    isEmptyOnSend: boolean;
    viewOnly: boolean;
    errorMessage: string;
}

export const Container = styled.div<InputInfo>`
    border: 1px solid #BFBFBF;
    position: relative;
    height: 47px;
    max-width: 587px;
    padding: 9px 20px;

    ${props => props.isEmptyOnSend && css`
        border: 1px solid #FF5656;
    `}

    ${props => props.isFocused && css`
        border: 1px solid #256EF3;
    `}

    ${props => (props.isErrored) && css`
        border: 1px solid #FF5656;
    `}

    div {
        display: flex;
        align-items: center;
    }
    
    span {
        color: #BEBEBE;
        margin: 0;
    }
    
    input {
        ${props => (props.viewOnly) && css`
            pointer-events: none;
        `}

        border: none;
        width: 100%;

        padding: 0 20px;

        font-size: 14px;
        line-height: 16px;
        color: #575757;

        &:focus {
            outline: none;
            border: none;
        }

    }

    &::after {
        content: '${props => props.errorMessage}';
        color: #FF5656;
        font-size: 12px;
        line-height: 14px;

        position: absolute;
        left: 0;
        top: calc(100% + 5px);

        ${props => !(props.isErrored) && css`
            display: none;
        `}
    }
`;