import React from "react";
import { AxiosResponse } from "axios";
import { useDetectClickOutside } from "react-detect-click-outside";

import { AdvertisersHttpClient } from "../../../services/api/platform-api/advertisers-http-client";

import { Container, StyledSelectedIcon, RoleOption, Footer } from "./styles";


interface RoleManagementMenuProps {
    toggled: boolean,
    userId?: number,
    handleClickOutside: (e: Event) => void,
    removeUserHandler: (userId?: number) => void,
    untoggledHandler: () => void
};

function RoleManagementMenu(props: RoleManagementMenuProps) {
    const ref = useDetectClickOutside({ onTriggered: props.handleClickOutside });

    const removeUserClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        new AdvertisersHttpClient().removeUserFromAdvertiser(props.userId)
            .then((response: AxiosResponse) => {
                if (response.status === 401) {
                    alert("Usuário logado não possui permissão para efetuar esta ação!");
                    return;
                }

                if (response.status !== 200) {
                    alert("Algo inesperado aconteceu. Tente novamente mais tarde!");
                    return;
                }

                props.removeUserHandler(props.userId);
                props.untoggledHandler();
            })
            .catch(() => {
                alert("Algo inesperado aconteceu. Tente novamente mais tarde!");
            });
    };

    return (
        <Container ref={ref} className="card-shadow" toggled={props.toggled} >
            <RoleOption>
                <div className="role-info-container">
                    <div className="role-name">
                        Administrador
                    </div>
                    <div className="role-description">
                        Edição e acesso completo.
                    </div>
                </div>
                <StyledSelectedIcon />
            </RoleOption>
            <Footer onClick={removeUserClickHandler}>
                Remover usuário
            </Footer>
        </Container>
    );
}

export default RoleManagementMenu;
