import React, { useCallback, useState } from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { Container, CreativesData } from './styles';
import {IListCampaignCreativesProps} from "../../../../interfaces/Creatives/IListCampaignCreativesProps";
import ModalViewCreative from '../ModalViewCreative';
import formatStatusString from '../../../../services/utils/formatStatusString';
import formatDate from '../../../../services/utils/formatDate';
import getStatusColor from '../../../../services/utils/getStatusColor';
import NumberFormat from 'react-number-format';


const CampaignCreatives: React.FC<IListCampaignCreativesProps> = ({creatives, displayImps}) => {
    const [showCreativeModal, setShowCreativeModal] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [imageDescription, setImageDescription] = useState('');

    const handleCreativeClick = useCallback((imageUrl, imageDescription) => {
        setImageUrl(imageUrl);
        setImageDescription(imageDescription);
        setShowCreativeModal(true);
    }, [setImageUrl, setImageDescription, setShowCreativeModal]);
    
    return (
        <Container className='card-shadow' isLastDiv={!displayImps}>
            <div className="card-shadow-title">
                <h2>Anúncios da Campanha</h2>
            </div>
            <div id="divisor">
                <div id="blue-contrast"></div>
                <div id="gray-line"></div>
            </div>

            <CreativesData>
                <div className='default-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Nome do anúncio</th>
                                <th>Início</th>
                                <th>Status</th>
                                {displayImps ? <th>Impressões</th> : ''}
                            </tr>
                        </thead>
                        <tbody>
                            {creatives?.map(creative => (
                                <tr key={creative.id}>
                                    <td>
                                        <button className='default-link' onClick={() => handleCreativeClick(creative.creative_details.cdn_image_url, creative.creative_details.name)}>
                                            {creative.creative_details.name}
                                        </button>
                                    </td>
                                    <td className='date'>{formatDate(creative.start_date)}</td>
                                    <td className='status'><BsFillCircleFill size={10} fill={getStatusColor(creative.state)} /> {formatStatusString(creative.state)}</td>
                                    {displayImps ? <td> <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','}
                                                                                       value={creative.impressions} /></td> : '' }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CreativesData>

            {showCreativeModal && (
                <ModalViewCreative  closeModal={() => setShowCreativeModal(false)} image_description={imageDescription} image_url={imageUrl} />
            )}
        </Container>
    );
}

export default CampaignCreatives;