import React, { useMemo, useEffect } from 'react';
import { useNewCampaignValues } from '../../../../pages/NewCampaign/NewCampaignContext';
import { Container } from './styles';
import { AiOutlineFieldTime } from 'react-icons/ai';
import DatePicker from '../../../Generic/DatePicker';
import { SelectInput } from '../../../Generic/FormElements/SelectInput';
import { TextInput } from '../../../Generic/FormElements/TextInput';
import { isValidUrl } from '../../../../services/utils/formValidators';

const GeneralDetail: React.FC = () => {
    const { 
        campaignName, 
        setCampaignName, 
        campaignNameIsEmpty, 
        campaignDuration, 
        setCampaignDuration,
        setCampaignDurationIsEmpty,
        campaignStartDate,
        setCampaignStartDate,
        campaignStartDateIsEmpty,
        clickUrl,
        setClickUrl,
        setClickUrlIsInvalid,
        clickUrlIsInvalid
    } = useNewCampaignValues();

    const minimumStartCampaignDate = useMemo(() => {
        const minimumDaysUntilCampaign = 5;

        const date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + minimumDaysUntilCampaign);
        return date;
    }, []);

    const validateClickUrl = (url: string) => {
        if(isValidUrl(url)) {
            setClickUrlIsInvalid(false);
            return true;
        } 

        setClickUrlIsInvalid(true);
        return false;
    }

    const validateCampaignDuration = (duration: any) => {
        setCampaignDurationIsEmpty(!!!duration);
        return !!duration;
    }

    useEffect(() => {
        setCampaignStartDate(minimumStartCampaignDate);
    }, []);

    return (
        <Container className='card-shadow'>
            <h2>Informações Gerais</h2>
            <div id="divisor">
                <div id="blue-contrast"></div>
                <div id="gray-line"></div>
            </div>
            <div className='content'>
                <div className='form-input-block'>
                    <span>Nome da campanha:</span>
                    <input className={(campaignNameIsEmpty && campaignName === '') ? 'errored-input' : 'default-input'} type="text" placeholder="Campanha" onChange={(event) => setCampaignName(event.target.value)} value={campaignName} />
                </div>
                <div className='form-input-block' id='date-input-block'>
                    <div>
                        <span>Data de início:</span>
                        <DatePicker isErrored={campaignStartDateIsEmpty} selectedDate={campaignStartDate} setSelectedDate={setCampaignStartDate} minimumDate={minimumStartCampaignDate} />
                    </div>


                    <div id='duration-time'>
                        <span>Tempo de Duração:</span>
                        <SelectInput
                            options={[
                                { value: 7, label: '7 dias' },
                                { value: 30, label: '30 dias' },
                                { value: 45, label: '45 dias' },
                                { value: 60, label: '60 dias' },
                                { value: 90, label: '90 dias' },
                                { value: 120, label: '120 dias' },
                            ]}
                            icon={<AiOutlineFieldTime />}
                            value={campaignDuration}
                            setValue={(newDuration) => setCampaignDuration(newDuration)}
                            validate={validateCampaignDuration}
                        />
                    </div>
                </div>
                <div className='form-input-block'>
                    <span>URL de clique da campanha:</span>
                    <TextInput hasError={clickUrlIsInvalid} setValue={setClickUrl} value={clickUrl} validate={validateClickUrl} placeholder='https://www.exemplo.com' />
                    <span id="url-description">Insira o link completo do seu site ou do seu conteúdo no ECBR</span>
                </div>
            </div>
        </Container>
    );
}

export default GeneralDetail;