import styled from 'styled-components';

import { ReactComponent as CurrentAdvertiserIcon } from '../../../assets/advertisers/curret-advertiser.svg';


export const Container = styled.div<{isCurrent: boolean}>`
    display: flex;
    padding: 9px;
    height: 55px;

    &:hover {
        ${props => !props.isCurrent && `
            background-color: rgba(211, 211, 211, 0.3);
            cursor: pointer;
        `};
    }
`;


export const AdvertiserIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 30px;
    min-width: 35px;
    margin-left: 10px;
    background-color: #A6A6A6;
    border-radius: 3px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: white;
`;


export const TitleContainer = styled.div`
    display: block;
    margin-left: 12px;

    #advertiser {
        margin: 0;
        
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #2C374C;
    }
    
    #email {
        display: block;
        overflow: auto;

        margin: 0;

        font-family: 'Mulish';
        font-style: normal;
        font-weight: 300;
        font-size: 11px;

        color: #444242;
    }
`

export const StyledCurrentAdvertiserIcon = styled(CurrentAdvertiserIcon)`
    margin-top: 12px;
    margin-left: 10px;
`
