import React, { useState } from 'react';
import { SelectInputContainer } from './styles';
import { FiChevronDown } from 'react-icons/fi';
import { InputPrototype } from '../InputPrototype';
import InputPrototypeProps from '../../../../interfaces/Components/FormElements/InputPrototypeProps';

interface SelectInputProps extends InputPrototypeProps {
    options: { label: string, value: any }[];
}

export const SelectInput: React.FC<SelectInputProps> = ({ icon, value, setValue, options, validate }: SelectInputProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    const findOptionLabel = (value: any) => {
        if(!options) return;

        const option = options.find(option => option.value === value);
        return option?.label;
    }

    const handleInputClick = () => {
        setShowDropdown(!showDropdown)

        if(validate && !showDropdown)
            setIsErrored(!validate(value));
    }

    return (
        <InputPrototype 
            handleClick={() => handleInputClick()} 
            value={value} 
            setValue={setValue} 
            isErrored={isErrored} 
            setIsErrored={setIsErrored} 
            isFocused={showDropdown}
            icon={icon}
        >
            <>
                <SelectInputContainer showDropdown={showDropdown}>
                    <input disabled value={findOptionLabel(value)} />
                    <ul>
                        {options.map((option, index) => <li key={index} onClick={() => setValue(option.value)}>{option.label}</li>)}
                    </ul>
                </SelectInputContainer>

                <FiChevronDown size={25} style={{ margin: 0, color: '#444242'}} className='select-icon' />
            </>

        </InputPrototype>
    )
};