import styled, { css } from "styled-components";

interface ContainerProps {
    isErrored: boolean;
    isFocused: boolean;
}

export const Container = styled.div<ContainerProps>`
    border: 1px solid #BFBFBF;

    ${props => props.isErrored && css`
        border: 1px solid #FF5656 !important;
    `}

    ${props => props.isFocused && css`
        border: 1px solid #256EF3 !important;
    `}
`;