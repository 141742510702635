import {PlatformAPIHttpClient} from "./platform-api-http-client";

export class UsersHttpClient extends PlatformAPIHttpClient {
    public getAuthenticatedUserInfo = () => this.instance.get('/users/me');

    public validateUserFirstPasswordToken = (token: string) => this.instance.get(`/users/first_password/${token}`);

    public defineUserFirstPassword = (
        token: string, 
        firstName: string, 
        lastName: string, 
        password: string, 
        confirmPassword: string
    ) => this.instance.put(`/users/first_password/${token}`, {
        first_name: firstName,
        last_name: lastName,
        password: password,
        confirm_password: confirmPassword
    });

    public changeCurrentAdvertiser = (advertiserId?: number) => {
        return this.instance.post(`/users/change_advertiser`, {
            advertiser_id: advertiserId
        });
    };
}