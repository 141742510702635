import { Container, SliderContainer, Slider, StarContainer } from "./styles";
import { FiX, FiArrowLeft, FiArrowRight, FiCircle } from 'react-icons/fi';
import { useContext, useEffect, useState } from "react";
import InfoSlider from "./InfoSlider";
import onboardingImg0 from '../../assets/onboarding/onboarding0.svg';
import onboardingImg1 from '../../assets/onboarding/onboarding1.svg';
import onboardingImg2 from '../../assets/onboarding/onboarding2.svg';
import onboardingImg3 from '../../assets/onboarding/onboarding3.svg';
import starImg from '../../assets/onboarding/star.svg';
import { UserContext } from "../../context/UserContext";


export default function Onboarding() {
    const [selectedOption, setSelectedOption] = useState(0);
    const [showSlider, setShowSlider] = useState(false);
    const [firstAccess, setFirstAccess] = useState(false);

    const { user } = useContext(UserContext);
    
    useEffect(() => {
        if  (user?.isFirstAccess) {
            setShowSlider(true);
            setFirstAccess(true);
        }
    }, []);

    function handleArrowLeftClick() {
        if (selectedOption === 0) {
            setSelectedOption(3);
        } else {
            setSelectedOption(selectedOption - 1);
        }
    }

    function handleArrowRightClick() {
        if (selectedOption === 3) {
            setSelectedOption(0);
        } else {
            setSelectedOption(selectedOption + 1);
        }
    }

    function handleToggleOnboarding() {
        setShowSlider(!showSlider);

        if (firstAccess) {
            setFirstAccess(false);
        }
    }

    return (
        <Container>
            <SliderContainer showSlider={showSlider} >
                <div className="top-label">
                    <span>Bem-vindo ao ECBR Ads!</span>
                    <FiX size={15} onClick={() => handleToggleOnboarding()} style={{ cursor: 'pointer' }} />
                </div>
                    <Slider selectedOption={selectedOption}>
                        <div className="info-container">
                            <InfoSlider 
                                src={onboardingImg0} 
                                title='Você pode acessar públicos específicos de leitores no E-commerce Brasil' 
                                description="Primeiro você escolhe a audiência por categorias que melhor combinam com o a sua marca e seus serviços, e define palavras-chaves de assuntos no ECBR que você acredita estarem relacionadas ao perfil de pessoas do seu interesse!"
                            />

                            <InfoSlider 
                                src={onboardingImg1} 
                                title='Você pode rever e conferir os valores de investimento acordados na proposta' 
                                description="O valor do seu CPM é fixo, de acordo com o cenário de proposta que você aceitou previamente. Na plataforma, você confirma o orçamento que deseja investir na sua campanha ECBR Ads."
                            />

                            <InfoSlider
                                src={onboardingImg2}
                                title='Sua campanha será ativada após todas as etapas de configuração serem concluídas' 
                                description="Para concluir a ativação da sua campanha, você receberá os próximos passos ainda na plataforma e também no seu e-mail. Após cumprir essas etapas, sua campanha será ativada!"
                            />

                            <InfoSlider 
                                src={onboardingImg3} 
                                title='Tenha acesso a um relatório completo na sua plataforma para coletar insights' 
                                description="Acompanhar sua campanha ECBR Ads é bem simples, você terá acesso as principais métricas da campanha direto na plataforma. Fique por dentro de dados e insights como alcance, impressões, melhores públicos, melhores canais, entre outros!"
                            />
                        </div>
                    </Slider>


                <div className="slider-options">
                    <FiArrowLeft color='#004993' className="arrow-left" size={20} onClick={() => handleArrowLeftClick()} />
                    <FiCircle fill={selectedOption === 0 ? '#004993' : '#BFCBD8'} color='transparent' size={8} onClick={() => setSelectedOption(0)} />
                    <FiCircle fill={selectedOption === 1 ? '#004993' : '#BFCBD8'} color='transparent' size={8} onClick={() => setSelectedOption(1)} />
                    <FiCircle fill={selectedOption === 2 ? '#004993' : '#BFCBD8'} color='transparent' size={8} onClick={() => setSelectedOption(2)} />
                    <FiCircle fill={selectedOption === 3 ? '#004993' : '#BFCBD8'} color='transparent' size={8} onClick={() => setSelectedOption(3)} />
                    <FiArrowRight color='#004993' className="arrow-right" size={20} onClick={() => handleArrowRightClick()} />
                </div>
            </SliderContainer>
            <StarContainer onClick={() => handleToggleOnboarding()}>
                <img src={starImg} alt="" />
                {firstAccess && (
                    <div className="first-show">
                        <FiCircle color="#FF5656" fill="#FF5656" size={6} />
                    </div>
                )}
            </StarContainer>
        </Container>
    )
}