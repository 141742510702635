import React from "react";

import {
    Container,
    UserIcon,
    UserInfoContainer,
    RoleInfoContainer
} from "./styles";


interface LoadingUserItemProps {
    opacity: number
}

function LoadingUserItem(props: LoadingUserItemProps) {
    return (
        <Container>
            <UserIcon opacity={props.opacity} />
            <UserInfoContainer opacity={props.opacity}>
                <div className="name-container" />
                <div className="email-container" />
            </UserInfoContainer>
            <RoleInfoContainer opacity={props.opacity} />
        </Container>
    );
}

export default LoadingUserItem;
