import React, { useCallback, useState } from 'react';
import { Container } from './styles';

interface InvestmentInputProps {
    value: number;
    setValue(value: number): void;
    viewOnly: boolean;
    isEmpty: boolean;
    placeholder?: number;
}

function InvestmentInput({ value, setValue, viewOnly, placeholder, isEmpty }: InvestmentInputProps) {
    const [isFocused, setIsFocused] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const validateInput = useCallback((newValue) => {
        if(viewOnly) return;

        setValue(newValue === '' ? 0 : newValue);
        const reg = /^[0-9]*\.?[0-9]*$/;

        if(!(reg.test(newValue)) && newValue !== ''){
            setIsErrored(true);
            setErrorMessage('Formato inválido, use apenas números e ponto, caso o valor tenha centavos');
        } else if (parseFloat(newValue) < 0.01) {
            setIsErrored(true);
            setErrorMessage('Valor inválido, passe um valor acima de R$ 0.01 BRL');
        } else {
            setIsErrored(false);
        }
        
    }, [setValue, setIsErrored, setErrorMessage, viewOnly]);

    return (
        <Container isFocused={isFocused} isErrored={isErrored} isEmptyOnSend={isEmpty && value === 0} errorMessage={`${errorMessage}`} viewOnly={viewOnly}>
            <div onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
                <span>R$</span><input type="text" onChange={(event) => validateInput(event.target.value)} value={value === 0 ? '' : value} placeholder = {placeholder ? `${placeholder}` : ''} /><span>BRL</span>
            </div>
        </Container>
    );
}

export default InvestmentInput;