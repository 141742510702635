import { AxiosError, AxiosResponse } from "axios";

import React, { useState, useEffect, useContext } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import { UserContext } from "../../../context/UserContext";

import UserItem from "../UserItem";
import LoadingUserItem from "../LoadingUserItem";

import { AdvertisersHttpClient } from "../../../services/api/platform-api/advertisers-http-client";

import {
    Container,
    Header,
    Main,
    Footer,
    InviteInput,
    InviteButton
} from "./styles";


interface UserManagementMenuProps {
    toggled: boolean,
    loading: boolean,
    userList: any[],
    handleClickOutside: (e: Event) => void,
    removeUserHandler: (userId?: number) => void
}

function UserManagementMenu(props: UserManagementMenuProps) {
    const { user } = useContext(UserContext);
    const [opacity, setOpacity] = useState(1);
    const [loadingIntervalId, setLoadingIntervalId] = useState<any>();
    const [inviteEmail, setInviteEmail] = useState("");
    const ref = useDetectClickOutside({ onTriggered: props.handleClickOutside });

    const oscillateOpacity = () => {
        setOpacity(prevState => prevState===1 ? 0.5 : 1);
    };

    useEffect(() => {
        if (!props.loading) {
            clearInterval(loadingIntervalId);
            return;
        }

        setLoadingIntervalId(setInterval(oscillateOpacity, 400));
    }, [props.loading]);

    const handleUserInvitation = () => {
        const handleInvitationResponse = (statusCode?: number) => {
            switch (statusCode) {
                case 200:
                    alert('Email de convite enviado com sucesso!');
                    break;
                case 422:
                    alert('Dados inválidos inseridos!');
                    break;
                case 400:
                    alert('Ação inválida!');
                    break;
                default:
                    alert('Algo inesperado aconteceu. Tente novamente mais tarde!');
            };
        };

        if (!inviteEmail.trim()) {
            alert("Digite o e-mail do usuário que deseja convidar!");
            return;
        }

        new AdvertisersHttpClient().inviteUserToAdvertiser(inviteEmail)
            .then((response: AxiosResponse) => {
                handleInvitationResponse(response.status);
            })
            .catch((error: AxiosError) => {
                handleInvitationResponse(error.response?.status);
            });
    }

    return (
        <Container ref={ref} toggled={props.toggled} className="card-shadow">
            <Header loading={props.loading} opacity={opacity}>
                {props.loading ? "" : "USUÁRIOS"}
            </Header>

            {/* scrollable if there is more than 5 users except the logged in user */}
            <Main showScroll={props.userList.length - 1 > 5}>
                {
                    props.loading ?
                        <>
                            <LoadingUserItem opacity={opacity} />
                            <LoadingUserItem opacity={opacity} />
                            <LoadingUserItem opacity={opacity} />
                        </>
                        :
                        <></>
                }
                {
                    props.userList.map((value, index) => {
                        if (value.email===user?.email) {
                            return <></>;
                        }

                        return <UserItem
                            id={value.id}
                            firstName={value.first_name}
                            lastName={value.last_name}
                            email={value.email}
                            role={value.role}
                            removeUserHandler={props.removeUserHandler}
                        />
                    })
                }
                {(props.userList.length > 0 && !props.loading)? <div style={{ height: "100px" }} /> : <></>}
            </Main>
            <Footer>
                <InviteInput
                    loading={props.loading}
                    disabled={props.loading}
                    type="email"
                    placeholder={props.loading ? "" : "Adicione pessoas pelo email..."}
                    onChange={(e) => setInviteEmail(e.target.value)}
                />
                <InviteButton
                    loading={props.loading}
                    opacity={opacity}
                    disabled={props.loading}
                    onClick={handleUserInvitation}
                >
                    {props.loading ? "" : "Convidar"}
                </InviteButton>
            </Footer>
        </Container>
    );
}

export default UserManagementMenu;
