import {PlatformAPIHttpClient} from "./platform-api-http-client";

interface createCampaignDTO {
    name: string;
    segmentBudget: number;
    segmentMaxCPM: number;
    segmentKeywords: string[];
    segmentCategories: number[];
    redirectUrl: string;
    initDate: string;
    durationInDays: number;
}

interface SendPIDataDTO {
    campaignId: number;
    name: string;
    email: string;
}

export class CampaignsHttpClient extends PlatformAPIHttpClient {
    public createCampaign = ({ 
        name, 
        segmentBudget, 
        segmentMaxCPM, 
        segmentKeywords, 
        segmentCategories, 
        redirectUrl, 
        initDate, 
        durationInDays
    }: createCampaignDTO) => this.instance.post('/campaigns/create', {

        name: name,
        segment_budget: segmentBudget,
        segment_max_cpm: segmentMaxCPM,
        segment_keywords: segmentKeywords,
        segment_categories: segmentCategories,
        redirect_url: redirectUrl,
        init_date: initDate,
        duration_in_days: durationInDays
    });

    public getCampaignsList = () => this.instance.get('/campaigns/list');

    public getCampaignInfo = (campaignId: string) => this.instance.get(`/campaigns/view/${campaignId}`);

    public sendPiData = ({ campaignId, email, name}: SendPIDataDTO) => this.instance.put('/campaigns/pi-data', {
        campaign_id: campaignId,
        signature_resp_name: name,
        signature_resp_email: email
    });
}