import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    body, html {
      font-family: 'Mulish', sans-serif;
      background: #EFF1F5;
    }

    .white {
      background: #FFF;
    }

    .errored-input {
      border: 1px solid #FF5656;

      &:focus {
        outline: none;
        border: 1px solid #BFBFBF;
      }
    }

    .default-input {
      border: 1px solid #BFBFBF;

      &:focus {
        outline: none;
        border: 1px solid #256EF3;
      }
    }

    .default-input, .errored-input {
      flex: 1;

      height: 47px;
      max-width: 622px;

      padding: 0 20px;

      font-size: 14px;
      line-height: 16px;

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #8E8E8E;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #8E8E8E;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #8E8E8E;
      }
    }

    .default-link {
      color: #006DAC;
      font-size: 14px;
      text-decoration: none;

      cursor: pointer;
      background: none;
      border: none;

      &:active {
        color: #006DAC;
      }

      &:visited {
        color: #006DAC;
      }

      &:hover {
        color: #006DAC;
      }

      &:link {
        color: #006DAC;
      }
    }
    
    .centered-container {
      max-width: 867px;
      margin: 0 auto;
      padding-top: 90px;
    }

    .shadow-header {
      box-shadow: 0px 4px 10px #D1D9E7;
      background: #FFF;
    }

    .card-shadow {
      box-shadow: -2px 4px 10px #D1D9E7, 2px 4px 10px #D1D9E7;
      border-radius: 10px;
      background: #FFF;

      .card-shadow-title {
          padding: 30px 0 27px 60px;

          h2 {
            font-size: 18px;
            font-weight: bold;
            line-height: 25px;
            color: #444242;
          }
      }
      
      .default-table {
        table {
          border-collapse: collapse;

          width: 100%;

          tr {
            padding: 0 60px;

            th, td {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: #585858;
            }

            th {
              text-align: start;

              padding-top: 23px;
              padding-bottom: 10px;

              border-bottom: 1px solid #EBEBEB;

              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #444242;

            }

            td {
              padding: 20px 0;
              border-bottom: 1px solid #EBEBEB;
            }

            td:first-child, th:first-child {
              padding-left:60px;
            }
          }
        }
      }
    }
`;