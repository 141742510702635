import React from 'react';

import logoImg from '../../../assets/ECBR_Ads.png';

import CurrentAdvertiserButton from '../../Advertisers/CurrentAdvertiserButton';
import ManageUsersButton from '../../Users/ManageUsersButton';

import { Container } from './styles';

interface HeaderProps {
    title?: string;
}


const MainHeader: React.FC<HeaderProps> = ({ title }: HeaderProps) => {
    return (
        <Container className='shadow-header'>
            <img id='logo' src={logoImg} alt="ECBR Ads Logo" />
            <div id='title'>
              <h1>{title || 'Anunciante > Campanha'}</h1>
            </div>
            <ManageUsersButton />
            <CurrentAdvertiserButton />
        </Container>
    );
}

export default MainHeader;
