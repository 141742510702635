import React, { useEffect, useState } from 'react';
import Category from './Category';
import { Container } from './styles';
import {SellersHttpClient} from "../../../../services/api/platform-api/sellers-http-client";
import {CategoryInfo} from "../../../../interfaces/Campaigns/Segments/CategoryInfo";
import { useNewCampaignValues } from '../../../../pages/NewCampaign/NewCampaignContext';

interface CategoriesProps {
  viewOnly: boolean;
  viewOnlyCategories?: CategoryInfo[];
}

function Categories({viewOnly, viewOnlyCategories}: CategoriesProps) {
  const [categories, setCategories] = useState<CategoryInfo[]>([]);
  const { categoriesAndKeywordsAreEmpty, categoriesIDs } = useNewCampaignValues();

  useEffect(() => {
    if (viewOnly && viewOnlyCategories) {
      return setCategories(viewOnlyCategories);
    }
    new SellersHttpClient().getSiteCategories().then(response => setCategories(response.data.data));
  }, [viewOnly, viewOnlyCategories]);

  return (
    <Container>
        {categories.map(category => (
          <Category 
            key={category.id} 
            title={category.name} 
            description={category.description} 
            id={category.id} 
            viewOnly={viewOnly} 
            isEmptyOnSend={categoriesAndKeywordsAreEmpty && categoriesIDs.length === 0}
          />
        ))}
    </Container>
  );
}

export default Categories;