import React from 'react';
import { useHistory } from 'react-router-dom';

import { AdvertiserInfo } from '../../../interfaces/Advertisers/AdvertiserInfo';

import { UsersHttpClient } from '../../../services/api/platform-api/users-http-client';

import {
    Container,
    AdvertiserIcon,
    TitleContainer,
    StyledCurrentAdvertiserIcon
} from './styles';


interface AdvertiserOptionProps {
    email?: string,
    advertiserInfo?: AdvertiserInfo
    isCurrent: boolean
}


function AdvertiserOption(props: AdvertiserOptionProps) {
    const history = useHistory();

    const handleClick = () => {
        if (props.isCurrent)
            return;

        const usersHttpClient = new UsersHttpClient()

        usersHttpClient.changeCurrentAdvertiser(props.advertiserInfo?.id)
            .then((response: any) => {
                if (response.status !== 200) {
                    alert('Algo inesperado aconteceu. Tente novamente mais tarde!');
                    return;
                }
                history.go(0);
            })
            .catch(() => {
                alert('Algo inesperado aconteceu. Tente novamente mais tarde!');
            });
    };

    return (
        <Container isCurrent={props.isCurrent} onClick={handleClick}>
            <AdvertiserIcon>
                {props.advertiserInfo?.name.charAt(0).toUpperCase()}
            </AdvertiserIcon>
            <TitleContainer>
                <p id='advertiser'>
                    {props.advertiserInfo?.name}
                </p>
                <p id='email'>
                    {props.email}
                </p>
            </TitleContainer>
            {
                props.isCurrent ? <StyledCurrentAdvertiserIcon /> : <></>
            }
        </Container>
    );
}

export default AdvertiserOption;