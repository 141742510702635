import {GeneralContainer} from "../CampaignCreativesStatisticsDetail/styles";
import NumberFormat from 'react-number-format';


interface ICreativeStatisticsDetail {
    imageSrc: string;
    dataTitle: string;
    dataNumber: number;
    percentage: boolean;
}

const CreativeStatisticsDetail: React.FC<ICreativeStatisticsDetail> = ({ imageSrc, dataTitle, dataNumber, percentage }) => {
    return (
        <GeneralContainer>
            <h5>{dataTitle}</h5>
            <div className='data-values'>
                <img src={imageSrc} />
                <h4><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','}
                                  value={dataNumber} />{percentage ? '%' : ''}</h4>
            </div>
            <div id="divisor"><div id="blue-contrast"></div><div id="gray-line"></div></div>
        </GeneralContainer>
    );
}

export default CreativeStatisticsDetail;
