import {useContext, createContext, ReactNode, useState} from 'react';
import { UserContext } from '../../../context/UserContext';

interface CampaignValuesProviderProps {
    children: ReactNode;
}

interface CampaignValuesContextData {
    campaignName: string;
    setCampaignName(value: string): void;
    campaignDuration: number;
    setCampaignDuration(value: number): void;
    campaignStartDate: Date | null;
    setCampaignStartDate(value: Date | null): void;
    clickUrl: string;
    setClickUrl(value: string): void;
    keyword1: string;
    setKeyword1(value: string): void;
    keyword2: string;
    setKeyword2(value: string): void;
    keyword3: string;
    setKeyword3(value: string): void;
    keyword4: string;
    setKeyword4(value: string): void;
    budget: number;
    setBudget(value: number): void;
    cpmMax: number;
    setCpmMax(value: number): void;
    categoriesIDs: number[];
    setCategoriesIDs(value: number[]): void;
    campaignNameIsEmpty: boolean;
    setCampaignNameIsEmpty(value: boolean): void;
    campaignDurationIsEmpty: boolean;
    setCampaignDurationIsEmpty(value: boolean): void;
    campaignStartDateIsEmpty: boolean;
    setCampaignStartDateIsEmpty(value: boolean): void;
    clickUrlIsInvalid: boolean;
    setClickUrlIsInvalid(value: boolean): void;
    categoriesAndKeywordsAreEmpty: boolean;
    setCategoriesAndKeywordsAreEmpty(value: boolean): void;
    budgetIsEmpty: boolean;
    setBudgetIsEmpty(value: boolean): void;
    cpmMaxIsEmpty: boolean;
    setCpmMaxIsEmpty(value: boolean): void;

}

export const CampaignValuesContext = createContext<CampaignValuesContextData>({} as CampaignValuesContextData);


export function CampaignValuesProvider({ children }: CampaignValuesProviderProps){
    const { user } = useContext(UserContext);

    const [campaignName, setCampaignName] = useState('');
    const [campaignDuration, setCampaignDuration] = useState(7);
    const [campaignStartDate, setCampaignStartDate] = useState<Date | null>(null);
    const [clickUrl, setClickUrl] = useState('');
    const [keyword1, setKeyword1] = useState('');
    const [keyword2, setKeyword2] = useState('');
    const [keyword3, setKeyword3] = useState('');
    const [keyword4, setKeyword4] = useState('');
    const [budget, setBudget] = useState(0);
    const [cpmMax, setCpmMax] = useState(user?.currentAdvertiser.max_cpm || 0);
    const [categoriesIDs, setCategoriesIDs] = useState<number[]>([]);
    const [campaignNameIsEmpty, setCampaignNameIsEmpty] = useState(false);
    const [campaignDurationIsEmpty, setCampaignDurationIsEmpty] = useState(false);
    const [campaignStartDateIsEmpty, setCampaignStartDateIsEmpty] = useState(false);
    const [clickUrlIsInvalid, setClickUrlIsInvalid] = useState(false);

    const [categoriesAndKeywordsAreEmpty, setCategoriesAndKeywordsAreEmpty] = useState(false);
    const [budgetIsEmpty, setBudgetIsEmpty] = useState(false);
    const [cpmMaxIsEmpty, setCpmMaxIsEmpty] = useState(false);
   

    return(
        <CampaignValuesContext.Provider 
            value={ 
                {
                    campaignName, setCampaignName,
                    campaignDuration, setCampaignDuration,
                    campaignStartDate, setCampaignStartDate,
                    clickUrl, setClickUrl,
                    keyword1, setKeyword1,
                    keyword2, setKeyword2,
                    keyword3, setKeyword3,
                    keyword4, setKeyword4,
                    budget, setBudget,
                    cpmMax, setCpmMax,
                    categoriesIDs, setCategoriesIDs,
                    campaignNameIsEmpty, setCampaignNameIsEmpty,
                    campaignDurationIsEmpty, setCampaignDurationIsEmpty,
                    campaignStartDateIsEmpty, setCampaignStartDateIsEmpty,
                    clickUrlIsInvalid, setClickUrlIsInvalid,
                    categoriesAndKeywordsAreEmpty, setCategoriesAndKeywordsAreEmpty,
                    budgetIsEmpty, setBudgetIsEmpty,
                    cpmMaxIsEmpty, setCpmMaxIsEmpty
                } 
            }
        >
            { children }
        </CampaignValuesContext.Provider>
    )
    }



export function useNewCampaignValues() {
   const context = useContext(CampaignValuesContext);

   if(!context) {
       throw new Error('useNewCampaignValues must be used within an Provider');
  } 

   return context;
}