import {PlatformAPIHttpClient} from "./platform-api-http-client";

export class AdvertisersHttpClient extends PlatformAPIHttpClient {
    public getAdvertiserInfo = () => this.instance.get('/advertisers/info');

    public getAdvertiserUsers = () => this.instance.get('/advertisers/users');

    public removeUserFromAdvertiser = (userId?: number) => {
        return this.instance.delete(`/advertisers/users/${userId}/`);
    };

    public inviteUserToAdvertiser = (userEmail: string) => {
        return this.instance.post('/advertisers/users', {
            email: userEmail
        });
    };
}
