import styled, { css } from "styled-components";

interface SendFilesProps {
    loading: boolean;
    labelDisabled: boolean;
}

export const Container = styled.form`
    
`;

export const SendFiles = styled.div<SendFilesProps>`
    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    padding: 1rem 1.25rem;

    button {
        
    }

    span {
        display: block;
        text-align: center;
        font-size: .75rem;
        line-height: .9375rem;
        color: #96A0A7;
    }

    #creative-input {
        visibility: hidden;
        height: 0;
    }

    label {
        background: #004993;
        border: none;
        border: 1px solid #004993;
        border-radius: 4px;
        color: #FFF;

        width: 100%;
        text-align: center;

        transition: all ease 0.2s;

        padding: .5rem 1.1875rem;
        margin-bottom: 0;

        font-weight: 700;
        font-size: .875rem;
        line-height: 1.125rem;

        width: 100%;

        ${props => props.loading && css`
            svg {
                animation: loading 1s linear infinite;
            }
        `};

        &:disabled {
            background: rgba(0, 73, 147, 0.8);
            border: 1px solid rgba(0, 73, 147, 0.8);
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            background: #F6F7F9;
            color: #004993;
            cursor: pointer;
            border: 1px solid #CDCDCD;
        }

        &:focus {
            outline: none;
        }
    }
`;

export const Guide = styled.div`
    border-top: 1px solid #EBEBEB;
    padding: 1rem 1.25rem;

    span {
        display: block;

        font-size: .75rem;
        line-height: .9375rem;
        color: #96A0A7;

        font-weight: 700;
        margin-bottom: .625rem;
    }

    a {
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.125rem;
        text-decoration-line: underline;
        color: #256EF3;
    }

    
`;
