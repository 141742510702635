import styled, {css} from "styled-components";

export const Container = styled.div`
    margin: 60px 0;
    height: 600px;
`;

export const InstructionsContainer = styled.div`
    margin: 60px 0 0;
`;

export const Header = styled.div`
    padding: 28px 0 20px 60px;
    border-bottom: 1px solid #EBEBEB;

    h2{
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        color: #444242;
    }
`

export const Phrase = styled.p`
    padding: 30px 0 0 60px;

    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #8E8E8E;
`

export const Main = styled.div`
  padding: 10px 0 51px 75px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 72px;
`

export const Steps = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    .step {
        display: flex;
        align-items: center;
        position: relative;

        .circle{
            height: 20px;
            width: 20px;   
            border-radius: 50%;
            border: 1px solid #256EF3;
            color: #256EF3;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        p{
            margin-left: 16px;
            color: #494949;
            padding-top: 17px;
        }
        
        .cross-line {
            color: #CFD2D9;
            text-decoration: line-through;
        } 

        .clickable-step {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #256EF3;

          padding-bottom: .1875rem;
          border-bottom: 1px solid #256EF3;
          cursor: pointer;
        }
      
      .uploadZip {
        color: #256EF3;
        border-bottom: 1px solid #256EF3;
        cursor: pointer;
      }
    }

`

export const ContainerCheckEmailText = styled.div`
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;
    width: 298px;

    h4{
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #585858;
        margin-bottom: 10px;
    }

    span {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #8E8E8E;
    }
`

export const ContainerSendBanners =  styled.div`
  width: 100%;
  height: 0px;
`;

interface ShowUploadProp {
    show: boolean
}

export const ContainerSendBannersContent = styled.div<ShowUploadProp>`
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 0px 12px #D1D9E7;
  margin: -11px 0 0 125px;
  height: 142px;
  width: 303px;
  display: none;
  ${props => (props.show) && css`
    display: block;
  `}
  
  .upload-options {
    padding: 12px 0 0 27px;
    color: #004993;
    height: 47px;
    border-bottom: 1px solid #EBEBEB;
    height: 47px;
  }
  
  p {
    height: 35px;
    border-bottom: 1px solid #006DAC;
    width: 55px;
    padding-left: 3px;
  }
  
  button {
    width: 263px;
    height: 37px;
    text-align: center;
    color: #FFF;
    font-weight: 700;
    background: #004993;
    border-radius: 4px;
    margin: 12px 20px 10px 20px;
    cursor: pointer;
    border: none;
  }
  
  button:hover {
    color: #004993;
    background: #F6F7F9;
    border: 1px solid #CDCDCD;
  }
  
  button .spinner-border {
    width: 1.3rem;
    height: 1.3rem;
    border-width: 0.13em
  }
  
  span {
    color: #96A0A7;
    font-size: 14px;
    text-align: center;
    padding: 0 0 15px 20px;
  }
`;

interface ResponseProps {
    error: boolean;
}

export const ContainerResponseSendBanners = styled.div<ResponseProps>`
  background: #1BCC62;
  color: #FFF;
  font-size: 14px;
  padding: 20px;
  position: absolute;
  left: 80px;
  margin-top: 30px;
  display: inline-block;

  ${props => (props.error) && css`
    background: #FF5656;
  `}
  
  p {
    width: 239px;
  }
`;