import styled, { css } from "styled-components";

interface categoryInfo {
    viewOnly: boolean;
    isEmptyOnSend: boolean;
}

export const Container = styled.div<categoryInfo>`
    display: flex;

    & + div{
        margin-top: 30px;
    }

    .unchecked-checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid #9C9C9C;
        border-radius: 1px;

        ${props => props.isEmptyOnSend && css`
            border-color: #FF5656;
        `}

        margin-right: 15px;
        margin-top: 9px;
    }

    .checked-checkbox {
       
    }

    svg {
        margin-right: 15px;
        margin-top: 9px;

        fill: #FFF;
        background: #004993;

        ${props => props.viewOnly && css`
            background: #9C9C9C;
        `}
        padding: 4px;
        stroke-width: 4em;
        stroke: #FFF;
    }

    div {
        h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #585858;
        }

        p {
            font-size: 14px;
            line-height: 16px;
            color: #838383;

            margin-top: 7px;
            max-width: 402px;
        }
    }
`;