import styled, {css} from "styled-components";

interface CampaignCreativesProps {
    isLastDiv: boolean;
}

export const Container = styled.div<CampaignCreativesProps>`
    margin: 40px 0 0 0;
    ${props => (props.isLastDiv) && css`
      margin-bottom: 135px;
    `}
    padding-bottom: 42px;

    td {
      color: #96A0A7 !important;
    }
  
    td:first-child {
      width: 443px;
    }
  
    td.date {
      width: 110px;
    }
  
    td.status {
      width: 160px;
    };
  
    
  
    #divisor {
        display: flex;
        align-items: center;
        #gray-line {
            flex: 5;
            height: 1px;
            background: #EBEBEB;
        }

        #blue-contrast {
            flex: 1;
            width: 140px;
            height: 6px;
            background: #004993;
        }
    }

    h2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;

        color: #444242;
    }
`;

export const CreativesData = styled.div`
   
`;