import {PercentageTableListObject} from "../../components/PercentageTableList/interface/PercentageTableListObject";
import {ImpressionsByDomainData} from "../../interfaces/CampaignReport/Domains/ImpressionsByDomainData";

export default function parseBestDomainsToPercentObject(impsByDomain: ImpressionsByDomainData[], totalImps: number):PercentageTableListObject[] {
    let bestDomains:PercentageTableListObject[] = [];

    impsByDomain.forEach(function (item:any) {
        let domainPercentage = (100 * item.impressions) / totalImps;

        if(domainPercentage >= 7)
            domainPercentage = 100;
        else if(domainPercentage >= 2)
            domainPercentage = 40;
        else
            domainPercentage = 10;

        bestDomains.push({
            'name': item.domain,
            'percentage': domainPercentage
        });
    });

    return bestDomains;
}