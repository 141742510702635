import styled from "styled-components";

export const Container = styled.div<{ viewOnly: boolean }>`
    margin-top: 40px;
    height: 815px;
    max-width: 45.4375rem;
  
    h2 {
        font-weight: bold;
        font-size: 18px;
        color: #444242;
    }

    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #444242;
    }

    #divisor {
        display: flex;
        align-items: center;
        #gray-line {
            flex: 5;
            height: 1px;
            background: #EBEBEB;
        }

        #blue-contrast {
            flex: 1;
            width: 140px;
            height: 6px;
            background: #004993;
        }
    }

    .categorias {
        display: flex;

        border-bottom: 1px solid #EBEBEB;
        padding-left: 60px;

        span {
            border-right: 1px solid #EBEBEB;
            padding: 30px 47px 0 0;
            margin-right: 50px;
        }

    }

    .keywords {
        padding-top: 31px;

        & > div {
            max-width: 740px;
            margin: 0 auto;
        }

        .keywords-description {
            display: flex;
            flex-direction: column;

            margin-right: 56px;

            span {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #444242;
            }
            
            p {
                margin-top: 7px;
                font-size: 12px;
                line-height: 14px;
                color: #8E8E8E;
            }
        }


        .keywords-inputs {
            display: flex;
            flex-wrap: wrap;

            
            div {
                width: 283px;
                ${props => props.viewOnly && `width: 346px;`}
                height: 47px;
            }
            
            div:nth-of-type(2n+1) {
                margin-right: 6%;
            }

            div:nth-of-type(3),  div:nth-of-type(4){
                margin-top: 30px;
            }
        }
    }
`;