import styled from "styled-components";
import bgImg from "../../assets/bg-login.png";

export const BaseContainer = styled.div`
display: flex;
  & {
    height: 100%;
  }
`;

export const Footer = styled.p`
  font-size: 9px;
  position: absolute;
  bottom: 50px;
  right: 50px;

  line-height: 16px;
  color: #9C9C9C;

  text-align: right;
  padding-right: 30px;


  @media only screen and (max-height: 768px) {
    margin-bottom: 60px !important;
  }

  a, a:visited, a:hover, a:active {
      color: #256EF3;
  }
`;

export const ContainerAuth = styled.div` 

  background: #FFF;
  position: relative;

  & {
    min-height: 100vh;
    flex: 10;
    padding: 81px 0 0 188px;
    width: 55%;
  }
  
  header {
    margin-bottom: 70px;
  }
  
  
  label, h1 {
    color: #585858;
    font-weight: 600;
  }
  
  section a {
    margin-left: 255px;
  }
  
  h1 {
    font-size: 20px;
    font-weight: 700;
    color: #444242;
  }
  
  p {
    color: #96A0A7;
    font-size: 14px;
    margin: 9px 0 37px 0;
  }

  label {
    color: #444242;
    font-weight: 700;
    font-size: 14px;
  }

  section p {
    max-width: 410px;
  }
  
  .error-box {
    margin-top: 30px;
    width: 571px;
    white-space: pre-wrap;
  }
`;

export const CarrouselContainer = styled.div`
    & {
      flex: 9.3939;
      width: 45%;
      min-height: 100vh;
      background: url(${bgImg});
      background-size: auto 100%;
      /* background-repeat: no-repeat; */
      background-position: left top;

      display: flex;
      align-items: center;
      justify-content: center;

      .alice-carousel {
        padding-top: 93px;
      }

      .alice-carousel__dots-item.__active {
        background-color: #FFF;
        border-radius: 10px;
        height: 10px;
        width: 24px;
      }
      
      .alice-carousel__dots-item {
        background: #8A98B2;
        height: 10px;
        width: 10px;
      }

      .alice-carousel__dots-item:hover {
        background: #8A98B2;
      }

      .alice-carousel__dots-item.__active:hover {
        background: #FFF;
      }
      
      .alice-carousel__wrapper {

        .text-carrousel {
          width: 541px;
          padding-top: 60px;
          margin: 0 auto 60px;

          h1 {
            font-size: 1.875rem;
          }
        }
        
        .img-carrousel {
          width: 100%;
          display: flex;
          justify-content: center;
          
          img {
            max-width: 500px;
            max-height: 400px;
          }
        }
      }
      
      h1 {
        color: #FFF;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        font-weight: 600;
      }
      
      p {
        color: #F5F5F5;
        font-family: 'Work Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        padding-top: 20px;
        text-align: center;
      }
    }
`;