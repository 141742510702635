import styled from "styled-components";

export const Container = styled.div`
    position: sticky;
    top: 0;
    height: 3.75rem;
    display: flex;
    background-color: #fff;
    box-shadow: 0px 4px 10px #D1D9E7;
    z-index: 99;

    button {
        border-radius: 0;
        width: 13.4375rem;
        font-size: 16px;
    }
`;

export const PageTitleContainer = styled.div`
    display: flex;
    align-items: center;

    font-size: 1rem;
    line-height: 1.25rem;
    color: #444242;

    padding-left: 3.875rem;
`;