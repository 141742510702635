import { Container, ToastContainer } from "./styles";
import { useState, useEffect } from 'react';

interface ToastProps {
    toastList: ToastItemProps[];
    position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

interface ToastItemProps {
    type: 'success' | 'error';
    message: string;
}

const Toast = ({ position, toastList }: ToastProps) => {
    const [list, setList] = useState<ToastItemProps[]>(toastList);

    useEffect(() => {
        setList(toastList);

        const removeToastInterval = setInterval(() => {
            if(list.length > 0)
                removeToast(list.length - 1);
        }, 10000);

        return () => {
            clearInterval(removeToastInterval);
        }
    }, [toastList, list]);

    const removeToast = (index: number) => {
        const newList = list.splice(index, 1);
        setList(newList);
    }

    return (
        <Container position={position}>
            {list.map((toast, index) => (
                <ToastContainer key={index} type={toast.type}>
                    {toast.message}
                    <button onClick={() => removeToast(index)} type="button">&#10005;</button>
                </ToastContainer>
            ))}
        </Container>
    )
}

export default Toast;