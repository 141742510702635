import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 380px;
    margin: 0 26px;
    
    .img-container {
        width: 328px !important;

    }

    .title {
        margin-top: 12px;
        display: block;
        
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        color: #004993;
        max-width: 328px;
    }

    .description {
        margin-top: 15px;
        display: block;

        font-size: 14px;
        line-height: 20px;
        color: #444242;
        max-width: 328px;
    }
`;