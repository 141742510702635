import { Container, SkeletonHeader, SkeletonBody } from './styles';

const SkeletonCardDisplay: React.FC = () => {    
    return (
        <Container className='card-shadow'>
            <SkeletonHeader>
                <div id="skeleton-title">
                    <div className="blue-fill"></div>
                </div>
            </SkeletonHeader>

            <SkeletonBody>
                <div className='skeleton-line'>
                    <div className='first-column'>
                        <div className='gray-fill skeleton-data' style={{ width: '83px' }}></div>
                    </div>
                    <div>
                        <div className='second-column'>
                            <div className='gray-fill skeleton-data' style={{ width: '83px' }}></div>
                        </div>
                        <div className="third-column">
                            <div className='gray-fill skeleton-data' style={{ width: '152px' }}></div>
                        </div>
                    </div>
                </div>

                <div className='skeleton-line gray-background'>
                    <div className='first-column'>
                        <div className='blue-fill skeleton-data' style={{ width: '126px' }}></div>
                    </div>
                    <div>
                        <div className='second-column'>
                            <div className='gray-fill skeleton-data' style={{ width: '14px' }}></div>
                            <div className='gray-fill skeleton-data' style={{ width: '61px' }}></div>
                        </div>
                        <div className="third-column">
                            <div className='gray-fill skeleton-data' style={{ width: '83px' }}></div>
                        </div>
                    </div>
                </div>

                <div className='skeleton-line gray-background'>
                    <div className='first-column'>
                        <div className='blue-fill skeleton-data' style={{ width: '126px' }}></div>
                    </div>
                    <div>
                        <div className='second-column'>
                            <div className='gray-fill skeleton-data' style={{ width: '14px' }}></div>
                            <div className='gray-fill skeleton-data' style={{ width: '61px' }}></div>
                        </div>
                        <div className="third-column">
                            <div className='gray-fill skeleton-data' style={{ width: '83px' }}></div>
                        </div>
                    </div>
                </div>

                <div className='skeleton-line gray-background'>
                    <div className='first-column'>
                        <div className='blue-fill skeleton-data' style={{ width: '126px' }}></div>
                    </div>
                    <div>
                        <div className='second-column'>
                            <div className='gray-fill skeleton-data' style={{ width: '14px' }}></div>
                            <div className='gray-fill skeleton-data' style={{ width: '61px' }}></div>
                        </div>
                        <div className="third-column">
                            <div className='gray-fill skeleton-data' style={{ width: '83px' }}></div>
                        </div>
                    </div>
                </div>
            </SkeletonBody>

            
        </Container>
    );
}

export default SkeletonCardDisplay;