import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from './styles';
import InvestmentInput from '../InvestmentInput';
import { useNewCampaignValues } from '../../../../pages/NewCampaign/NewCampaignContext';
import {InvestmentProps} from "../../../../interfaces/Campaigns/CampaignDetails/InvestmentProps";

function InvestmentDetail({ viewOnlyBudget, viewOnlyCpmMax }: InvestmentProps) {
    const { 
        budget, 
        setBudget, 
        cpmMax, 
        setCpmMax,
        budgetIsEmpty,
        cpmMaxIsEmpty
    } = useNewCampaignValues();
    
    const location = useLocation().pathname;

    const viewOnly = useMemo(() => {
      return location.startsWith('/campaign-details');
    }, [location]);

    return (
        <Container className='card-shadow'>
            <div className='card-shadow-title'>
                <h2>Investimento</h2>
            </div>
            <div id="divisor">
                <div id="blue-contrast"></div>
                <div id="gray-line"></div>
            </div>
            <div className="form-inputs">
                <div className="first-input">
                    <span className="help-span">Orçamento: </span>
                    <InvestmentInput 
                        value={budget} 
                        setValue={setBudget} 
                        viewOnly={viewOnly} 
                        placeholder={viewOnlyBudget} 
                        isEmpty={budgetIsEmpty} 
                    />
                </div>
                <div className="second-input">
                    <span className="help-span">CPM: </span>
                    <InvestmentInput 
                        value={cpmMax} 
                        setValue={setCpmMax} 
                        viewOnly={viewOnly || cpmMax > 0}
                        placeholder={viewOnlyCpmMax || cpmMax} 
                        isEmpty={cpmMaxIsEmpty} 
                    />
                </div>
            </div>
        </Container>
    );
}

export default InvestmentDetail;