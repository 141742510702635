import styled from "styled-components";

export const Container = styled.input`
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    color: #444242;

    &:focus {
        outline: none;
    }
`;