import { useEffect, useContext } from 'react';

import { UserContext } from '../../context/UserContext';
import { UsersHttpClient } from '../api/platform-api/users-http-client';
import { LoginHttpClient } from '../api/platform-api/login-http-client';


interface IAppRoute {
    setLoading(newValue: boolean): void;
    setAuth(newValue: boolean): void;
}


export function AppRoute({setLoading, setAuth}: IAppRoute) {
    const { updateUser } = useContext(UserContext);

    useEffect(() => {
        const fetchLogged = async () => {
            try {
                setLoading(true);
                const result = await new UsersHttpClient().getAuthenticatedUserInfo();
                if (result.status === 200) {
                    setAuth(true);

                    const { 
                        email, 
                        user_type,
                        current_advertiser, 
                        available_advertisers,
                        is_first_access
                    } = result.data.data;

                    updateUser?.({
                        email: email,
                        userType: user_type,
                        currentAdvertiser: current_advertiser,
                        availableAdvertisers: available_advertisers,
                        isFirstAccess: is_first_access
                    });
                }
            } catch (e) {
                setAuth(false);
                await new LoginHttpClient().logout(); // In case token expired
            }

            setLoading(false);
        }

        fetchLogged();
    }, [setLoading, setAuth]);
}

export default AppRoute;
