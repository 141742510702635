import React from "react";
import {CarrouselContainer} from "../../../pages/Authenticate/styles";
import AliceCarousel from "react-alice-carousel";
import CarrouselImage from "./CarrouselImage";
import carrousel1 from '../../../assets/carrousel-1.png';
import carrousel2 from '../../../assets/carrousel-2.png';
import carrousel3 from '../../../assets/carrousel-3.png';

const InfoCarrousel: React.FC = () => {
    const items = [
        <CarrouselImage key="1" src={carrousel1} alt="Carrousel 1"
                        title="Promova seus conteúdos"
                        text="Aumente o alcance dos seus conteúdos publicados no ECBR,<br />fazendo sua mensagem chegar a mais pessoas e promovendo<br />sua marca como especialista." />,
        <CarrouselImage key="2" src={carrousel2} alt="Carrousel 2"
                        title="Faça sua marca ser lembrada"
                        text="Crie campanhas sobre sua marca, seus produtos e serviços,<br />e as distribua para milhares de profissionais do e-commerce<br />brasileiro com campanhas de display customizadas." />,
        <CarrouselImage key="3" src={carrousel3} alt="Carrousel 3"
                        title="Impacte seu público<br /> onde ele estiver"
                        text="Mostre sua marca e seus conteúdos em diversos momentos<br />da jornada dos usuários, entregando uma experiência que<br />engaje e aumente a percepção da sua marca." />
    ];

    return (
        <CarrouselContainer>
            <AliceCarousel 
                disableButtonsControls 
                controlsStrategy="responsive" 
                mouseTracking 
                items={items} 
                autoPlay
                infinite
                autoPlayInterval={3000}
            />
        </CarrouselContainer>
    )
}

export default InfoCarrousel;