import React from 'react';

const UploadCreatives: React.FC = () => {
    return (
            <div>
                <h1>
                    Upload Test
                </h1>
                <h3>
                    File Upload using React!
                </h3>
                <div>
                    <input type="file" />
                    <button>
                        Upload!
                    </button>
                </div>
            </div>
        );
};

export default UploadCreatives;