import styled, { css } from "styled-components";

interface ContainerProps {
    showCard: boolean;
}

interface ControllerProps {
    selectedOption: number;
}

interface SendInfoProps {
    loading: boolean;
}

export const Container = styled.div<ContainerProps>`
    width: 21.0625rem;
    z-index: 1;
    cursor: default;

    background: #FFFFFF;
    box-shadow: 0px 0px 12px #D1D9E7;
    border-radius: 10px;

    position: absolute;
    top: 100%;
    left: 100%;

    display: none;

    ${props => props.showCard && css`
        display: block;
    `};
`;

export const Controller = styled.div<ControllerProps>`
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0 1.75rem;
        overflow: hidden;
        
        display: flex;
        align-items: center;

        border-bottom: 1px solid #EBEBEB;
        
        li {
            padding: .5625rem 0;
            cursor: pointer;

            font-size: .875rem;
            line-height: 1.125rem;
            color: #004993;

            div {
                padding: .375rem .75rem;
                border-radius: 4px;

                &:hover {
                    background: #F0F2F5;
                }
            }

            & + li {
                margin-left: 2.25rem;
            }
        }

        ${props => props.selectedOption !== undefined && css`
            li:nth-of-type(${props.selectedOption}) {
                border-bottom: 1px solid #006DAC;
            }
        `}
    }
`;

export const SendInfo = styled.div<SendInfoProps>`
    padding: .9375rem 1.25rem;

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    span {
        font-weight: 700;
        font-size: .75rem;
        line-height: .9375rem;
        color: #96A0A7;

        display: block;
        margin-bottom: .375rem;
    }

    p {
        font-size: .875rem;
        line-height: 140%;
        color: #444242;

        padding: 0 !important;
        margin: 0 !important;
    }

    .form-inputs {
        span {
            display: block;
            font-weight: 700;
            font-size: .875rem;
            line-height: 1.125rem;
            color: #444242;
            margin-top: 1rem;
        }

        div {
            height: 2.3125rem;
        }
    }

    button {
        margin-top: 1.5rem;
        padding: .5rem 0;
        width: 100%;

        ${props => props.loading && css`
            svg {
                animation: loading 1s linear infinite;
            }
        `};
    }
`;