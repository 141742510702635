import styled from "styled-components";

export const Container = styled.div`
  padding: 25px 50px;
  height: 60px;
  display: flex;
  align-items: center;

    @keyframes skeleton-animation {
        0% {
            background-position: -200px 0;
        }
        100% {
            background-position: calc(200px + 100%) 0;
        }
    }

    #title, .skeleton-button {
        background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
        background-size: 200px 100%;
        animation: skeleton-animation 2s ease-in-out infinite;
    }

  #logo {
    height: 40px;
    margin-right: 55px;
    margin-bottom: 5px;
  }

  #title {
    padding-left: 50px;
    border-left: 1px solid rgba(203, 203, 203, 0.5);

    height: 14px;
    width: 143px;
    background: #E1E7EE;
    border-radius: 7px;
  }

  h1 {
    width: 255px;
    height: 18px;
    margin-top: 10px;

    font-weight: 500;
    font-size: 15px;
    color: #585858;
  }

  #icon-container {
    display: flex;

    justify-content: center;
    align-items: center;

    height: 60px;
  }

  #buttons {
    margin-left: auto;
    display: flex;
  }

  .skeleton-button {
    width: 26px;
    height: 24px;
    background: #E1E7EE;
    border-radius: 4px;

    & + .skeleton-button {
        margin-left: 28px;
    }
  }
`;
