import styled from "styled-components";

interface SliderProps {
    selectedOption: number;
}

interface SlicerContainerProps {
    showSlider: boolean;
}

export const Container = styled.div`
    position: fixed;
    bottom: 20px;
    right: 28px;
`;

export const SliderContainer = styled.div<SlicerContainerProps>`
    ${props => !props.showSlider && 'display: none;'}

    min-width: 380px;
    
    background: #fff;
    box-shadow: 0px 4px 10px #D1D9E7;
    border-radius: 5px;

    .top-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px;

        border-bottom: 1px solid #EBEBEB;

        span {
            font-size: 12px;
            line-height: 20px;
            color: #96A0A7;
        }
    }

    .slider-options {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 35px;

        svg+svg {
            margin-left: 8px;
        }

        svg:first-of-type {
            margin-right: 22px;
        }

        svg:last-of-type {
            margin-left: 30px;
        }

        svg:hover {
            cursor: pointer;
        }

        .arrow-left:hover, .arrow-right:hover {
            color: #76A6D8 !important;
        }
    }
`;

export const Slider = styled.div<SliderProps>`
    padding: 17px 0 22px;
    position: sticky;
    overflow-x: hidden;
    min-height: 250px;

    .info-container {
        display: flex;
        max-width: 380px;

        position: absolute;
    
        div {
            width: 380px;
        }
    
        will-change: transform;
        transition: transform 0.5s ease-in-out;
        transform: translateX(${props => props.selectedOption * -380}px);
    }

`;

export const StarContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;

    margin-top: 12px;

    &:hover {
        cursor: pointer;
    }

    .first-show {
        position: absolute;
        top: -10px;
        right: 0;
    }
`;