import styled from "styled-components";

export const Container = styled.div<{ toggled: boolean }>`
  position: absolute;
  width: 433px;

  display: ${(props) => (props.toggled ? "block" : "none")};

  margin-top: 10px;
  margin-left: -1200%;

  border-radius: 11px;

  z-index: 99999;
`;

export const Header = styled.div<{ loading: boolean; opacity: number }>`
  width: 59px;
  height: 13px;

  margin: 12px 0 12px 15px;

  background: ${(props) =>
    props.loading ? `rgba(217, 217, 217, ${props.opacity})` : "white"};
  border-radius: 10px;

  color: #96a0a7;
  font-weight: 300;
  font-family: "Mulish";
  font-size: 10px;
`;

export const Main = styled.div<{ showScroll: boolean }>`
  ${(props) => props.showScroll && `
    max-height: 300px; 
    overflow-y: scroll;
    
  `};

  ::-webkit-scrollbar {
    width: 0;
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 54px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid #ebebeb;
  padding: 16px 23px 16px 23px;
`;

export const InviteInput = styled.input<{ loading: boolean }>`
  width: 283px;
  height: 37px;
  padding: 10px;

  font-family: "Mulish";
  font-weight: 400;
  font-size: 14px;

  color: #444242;

  border: 1px solid
    ${(props) => (props.loading ? "rgba(191, 191, 191, 0.5)" : "#bfbfbf")};

  &::placeholder {
    font-family: "Mulish";
    font-weight: 400;
    font-size: 14px;

    color: #96a0a7;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: white;
  }
`;

export const InviteButton = styled.button<{
  loading: boolean;
  opacity: number;
}>`
  width: 93px;
  height: 37px;

  margin-left: 15px;
  padding: 7px 9px 7px 9px;

  background: ${(props) =>
    props.loading ? `rgba(0, 73, 147, ${props.opacity})` : "#004993"};
  border: 1px solid #cdcdcd;
  border-radius: 4px;

  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;

  color: white;

  &:hover {
    ${(props) =>
      !props.loading &&
      `
      cursor: pointer;
      background: #f6f7f9;
      color: #004993;
    `}
  }

  &:focus {
    outline: none;
  }
`;
