import SkeletonCardDisplay from "../SkeletonCardDisplay";
import SkeletonHeader from "../SkeletonHeader";

const SkeletonPage: React.FC = () => {
    return (
        <>
            <SkeletonHeader />
            <SkeletonCardDisplay />
        </>
    )
}

export default SkeletonPage;