import styled from "styled-components";

import { ReactComponent as ExtendIcon } from "../../../assets/extend-icon.svg";

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  height: 54px;

  &:hover {
    background: #f1f1f1;
  }
`;

export const UserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  margin-left: 23px;

  background: #d3d3d3;
  border-radius: 4.15313px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 22.8422px;

  color: white;
`;

export const UserInfoContainer = styled.div`
  margin-left: 10px;

  .name-container {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #2c374c;
  }

  .email-container {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 300;
    font-size: 11px;

    color: #444242;
  }
`;

export const RoleInfoContainer = styled.div`
  display: flex;
  align-items: center;

  width: 111px;
  height: 32px;

  margin-left: auto;
  margin-right: 10px;
  padding: 7px 0px 9px 7px;

  border-radius: 6px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;

  color: #444242;

  user-select: none;

  &:hover {
    cursor: pointer;
    background: #e3e3e3;
  }
`;

export const StyledExtendIcon = styled(ExtendIcon)`
  margin-left: 8px;
`;
