import React, { useState } from "react";

import InvestmentDetail from "../../components/Campaigns/Generic/InvestmentDetail";
import GeneralDetail from "../../components/Campaigns/CreateCampaign/GeneralDetail";
import AudienceDetail from "../../components/Campaigns/CampaignDetails/AudienceDetail";
import NewCampaignButton from "../../components/Campaigns/CreateCampaign/NewCampaignButton";
import ModalCampaignCreated from "../../components/Campaigns/CreateCampaign/ModalCampaignCreated";

import { CampaignValuesProvider } from "./NewCampaignContext";

import { Container } from "./styles";
import GoBackHeader from "../../components/Generic/GoBackHeader";
import { ToastItemProps } from "../../interfaces/Toast/IToastInterfaces";
import Toast from "../../components/Generic/Toast";


const NovaCampanha: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [creatingCampaign, setCreatingCampaign] = useState(false);
  const [campaignId, setCampaignId] = useState<number | null>(null);
  const [toastList, setToastList] = useState<ToastItemProps[]>([]);

  return (
    <>
      <GoBackHeader 
        title="Nova Campanha" 
        warnUser 
        warnMessage="Você tem certeza que deseja abandonar a criação de campanha? As informações adicionadas serão perdidas."
      />
      <CampaignValuesProvider>

        <Container>
          <GeneralDetail />

          <AudienceDetail />

          <InvestmentDetail />

          <NewCampaignButton creatingCampaign={creatingCampaign} setCreatingCampaign={setCreatingCampaign} setToastList={setToastList} setCampaignId={setCampaignId} setShowModal={setShowModal}>CONCLUIR</NewCampaignButton>

        </Container>

        {showModal && (
          <ModalCampaignCreated campaignId={campaignId} closeModal={() => setShowModal(false)} />
        )} 

        <Toast position="bottom-left" toastList={toastList} />


      </CampaignValuesProvider>
    </>
  );
}

export default NovaCampanha;