import React, {useState, useEffect} from "react";
import parse from 'html-react-parser';
import Tooltip from "../../../Generic/Tooltip";
import {
    Container,
    Header,
    ContainerCheckEmailText,
    Main,
    Phrase,
    Steps,
    ContainerSendBanners,
    ContainerSendBannersContent,
    ContainerResponseSendBanners,
    InstructionsContainer
}
    from './styles';
import {CreativesHttpClient} from "../../../../services/api/platform-api/creatives-http-client";
import {AdvertisersHttpClient} from "../../../../services/api/platform-api/advertisers-http-client";
import InvestmentTermsCard from "../InvestmentTermsCard";
import UpdloadCreativesCard from "../UploadCreativesCard";
import { CampaignDetailData } from "../../../../interfaces/Campaigns/CampaignDetails/CampaignDetailData";

interface IStartingSteps {
    hasMissingSignature: boolean;
    hasMissingCreatives: boolean;
    campaigns: CampaignDetailData[];
}

function StartingSteps({ hasMissingCreatives, hasMissingSignature, campaigns }: IStartingSteps) {

    const [previousUploadedFile, setPreviousUploadedFile] = useState('');
    const [displayUpload, setDisplayUpload] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [responseMsg, setResponseMsg] = useState('');
    const [hasError, setHasError] = useState(false);
    const [showInvestmentTerms, setShowInvestmentTerms] = useState(false);
    const [showUploadCreatives, setShowUploadCreatives] = useState(false);
    const hiddenFileInput:any = React.useRef(null);


    useEffect(() => {
        new AdvertisersHttpClient().getAdvertiserInfo()
            .then((response:any) => {
                setPreviousUploadedFile(response.data.data.last_zip_file);
            })
    }, []);


    const handleDisplayUpload = () => {
        setDisplayUpload(!displayUpload);
        if(showResponse)
            setShowResponse(false);
    }

    const handleUploadButtonClick = () => {
        hiddenFileInput.current.click();
    };



    const handleUploadFileChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;

        if (!fileList) return;

        setIsLoading(true);

        new CreativesHttpClient().uploadCreative(fileList[0])
            .then(() => {
                setDisplayUpload(false);
                setPreviousUploadedFile(fileList[0].name);
                setResponseMsg('Arquivo enviado com sucesso!<br />Para enviar um novo, basta refazer o processo.');
                setHasError(false);
            }).catch(function(error) {
                if(error.response.status === 400)
                    setResponseMsg('Desculpe, ainda não permitimos<br /> arquivos diferentes de .zip');
                else if(error.response.status === 422)
                    setResponseMsg('Desculpe, ainda não permitimos formatos<br /> diferentes de .jpg e .png em arquivos .zip');
                else
                    setResponseMsg('Desculpe, um erro inesperado aconteceu. Tente novamente mais tarde!');

                setHasError(true);
            })
            .finally(() => {
                setIsLoading(false);
                setShowResponse(true);
                hiddenFileInput.current.value = null;
            });
    };


    return (
        <Container >
            <InstructionsContainer className='card-shadow'>
                <Header>
                    <h2>Ativação de campanha - Próximos passos</h2>
                </Header>

                <Phrase>Complete todas as etapas para ativar uma campanha no ECBRAds</Phrase>

                <Main>
                    <Steps>
                        <div className="step">
                            <div className='circle'>1</div>
                            <p className='cross-line'>Configure sua campanha</p>
                        </div>

                        <div className="step">
                            <div className='circle'>2</div>
                            <p className={hasMissingSignature ? "clickable-step" : " cross-line"} onClick={() => setShowInvestmentTerms(true)}>
                                Concluir termos de investimento
                            </p>
                            <Tooltip content='O termo de investimento será enviado para o responsável legal, apontado por você, para confirmar que o seu orçamento será dedicado à compra de mídia.' />
                            <InvestmentTermsCard campaigns={campaigns} showCard={hasMissingSignature ? showInvestmentTerms : false} setShowCard={setShowInvestmentTerms} />                                
                        </div>

                        <div className="step">
                            <div className='circle'>3</div>
                            <p className={hasMissingCreatives ? "clickable-step" : " cross-line"} onClick={() => setShowUploadCreatives(true)}>
                                Enviar seus banners para aprovação
                            </p>
                            <UpdloadCreativesCard showCard={hasMissingCreatives ? showUploadCreatives : false} setShowCard={setShowUploadCreatives} />
                        </div>
                    </Steps>

                    <ContainerCheckEmailText>
                        <h4>Confira seu e-mail</h4>
                        <span>As instruções sobre os passos 2, 3 e 4 foram enviadas para o seu e-mail, mas caso tenha dúvidas, consulte o nosso time através de <a href="mailto:ecbrads@voxus.tv?subject=Tenho%20uma%20d%C3%BAvida%20sobre%20minha%20campanha">ecbrads@voxus.tv</a>.</span>
                    </ContainerCheckEmailText>
                    <ContainerSendBanners>
                        <ContainerSendBannersContent show={displayUpload}>
                            <div className="upload-options">
                                <p>Anexar</p>
                            </div>
                            <button onClick={() => handleUploadButtonClick()} disabled={loading}>
                                {loading ?
                                    <div className="spinner-border spinner-border-sm" role="status">
                                    </div>
                                    : "Enviar arquivo compactado"}
                            </button>
                            <span>Permitimos apenas arquivos .zip</span>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleUploadFileChange}
                                accept=".zip, .7zip"
                                multiple={false}
                                style={{display: 'none'}} />
                        </ContainerSendBannersContent>
                    </ContainerSendBanners>
                </Main>
            </InstructionsContainer>
            {showResponse ?
                <ContainerResponseSendBanners error={hasError}>
                    {parse(responseMsg)}
                </ContainerResponseSendBanners>
                : null
            }
        </Container>
    )
}

export default StartingSteps;