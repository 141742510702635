import styled, { css } from "styled-components";

interface ContainerProps {
    loading: boolean;
}

export const Container = styled.div<ContainerProps>`
    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    padding: 1rem 1.25rem;

    span {
        font-weight: 700;
        font-size: .75rem;
        line-height: .9375rem;
        color: #96A0A7;
        display: block;

        margin-bottom: .625rem;
    }

    p {
        font-size: .875rem;
        line-height: 1.125rem;
        color: #444242;

        padding: 0 !important;

        margin: 0 0 1.0625rem 0 !important;
    }

    button {
        font-weight: 700;
        font-size: .875rem;
        line-height: 1.125rem;

        padding: .5rem 1.1875rem;
        margin-bottom: 1.375rem;
        width: 100%;

        ${props => props.loading && css`
            svg {
                animation: loading 1s linear infinite;
            }
        `};
    }
`;