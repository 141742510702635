import styled, { css } from "styled-components";

interface ContainerProps {
    show: boolean;
}

export const Container = styled.div<ContainerProps>`
    /* display: none; */

    ${props => props.show && css`
        display: block;
    `};
`;