import { Container, PageTitleContainer } from "./styles";
import Button from "../Button";
import { useHistory } from "react-router-dom";

interface GoBackHeaderProps {
    title: string;
    warnUser?: boolean;
    warnMessage?: string;
}

export default function GoBackHeader({ title, warnUser, warnMessage }: GoBackHeaderProps) {
    const history = useHistory();

    warnMessage = warnMessage || 'Tem certeza que deseja voltar? Todas as alterações não salvas serão perdidas.';

    const goBackExecute = () => {
        if (warnUser && !window.confirm(warnMessage))
            return;

        history.goBack();
    }

    return(
        <Container>
            <Button execute={goBackExecute}>{'< Voltar'}</Button>
            <PageTitleContainer>
                <span>{title}</span>
            </PageTitleContainer>
        </Container>
    );
}