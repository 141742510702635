import React, { useState, createContext, ReactNode } from 'react';

import { UserInfo } from '../interfaces/Users/UserInfo';


interface IUserContext {
    user: UserInfo,
    updateUser(userData: any): void
}


interface UserContextProps {
    children: ReactNode | undefined;
}


export const UserContext = createContext<Partial<IUserContext>>({});


export const UserProvider = (props: UserContextProps) => {
    const [user, setUser] = useState<UserInfo | undefined>();

    const updateUser = (userData: any) => {
        setUser(userData);
    }

    return (
        <UserContext.Provider value={{ user, updateUser }}>
            {props.children}
        </UserContext.Provider>
    );
}
