import styled, { css } from "styled-components";

export const SelectInputContainer = styled.div<{ showDropdown: boolean}>`
    display: flex;
    align-items: center;

    input {
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        color: #444242;
        width: 100px !important;

        &:hover {
            cursor: pointer;
        }

        &:disabled {
            color: #444242;
            background: transparent;
        }
    }

    .select-icon {
        color: #444242;
        margin: 0;
    }

    ul {
        display: none;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        background: #FFF;

        max-height: 130px;
        overflow-y: auto;

        background: #FFF;
        z-index: 9;

        width: 100%;
        padding: 10px 20px;
        margin: 0;
        border: 1px solid #BFBFBF;
        border-radius: 4px;
        
        list-style-type: none;

        li {
            & + li {
                margin-top: 20px;
            }

            &:hover {
                cursor: pointer;
            }
        }

    }

    ${props => props.showDropdown && css`
        ul {
            display: block;
        }
    `}
`;