import styled from "styled-components";

export const Container = styled.div`
  padding: 25px 50px;
  height: 60px;
  display: flex;
  align-items: center;

  #logo {
    height: 40px;
    margin-right: 55px;
    margin-bottom: 5px;
  }

  #title {
    height: 30px;
    padding-left: 50px;

    border-left: 1px solid rgba(203, 203, 203, 0.5);
    display: flex;
    align-items: center;
  }

  h1 {
    width: 255px;
    height: 18px;
    margin-top: 10px;

    font-weight: 500;
    font-size: 15px;
    color: #585858;
  }

  #icon-container {
    display: flex;

    justify-content: center;
    align-items: center;

    height: 60px;
  }
`;
