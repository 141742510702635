
import React from 'react';
import { HashRouter } from 'react-router-dom';

import { UserProvider } from './context/UserContext';
import GlobalStyles from './styles/global';
import Routes from './routes';

const App: React.FC = () => (
  <>
    <UserProvider>
      <HashRouter>
        <Routes />
      </HashRouter>
      <GlobalStyles/>
    </UserProvider>
  </>
);

export default App;