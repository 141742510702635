import React, { useContext, useState } from 'react';

import { UserContext } from '../../../context/UserContext';

import AvailableAdvertisersMenu from '../AvailableAdvertisersMenu';

import { 
    Container, 
    ButtonToolTip, 
    ButtonContainer, 
    ToggleMenuButton, 
} from './styles';


function CurrentAdvertiserButton() {
    const { user } = useContext(UserContext);
    const [toggled, setToggled] = useState(false);

    let toolTipTimeout: NodeJS.Timeout;
    const [toggleToolTip, setToggleToolTip] = useState(false);


    const clickHandler = () => {
        setToggled(prevState => !prevState);
    };

    const showToolTip = () => {
        toolTipTimeout = setTimeout(() => {
            setToggleToolTip(true);
        }, 400);
    };

    const hideToolTip = () => {
        clearInterval(toolTipTimeout);
        setToggleToolTip(false);
        removeButtonFocus();
    };

    const removeButtonFocus = () => {
        const button = document.getElementById('toggle-menu-button');
        button?.blur();
    };

    const hideMenu = (e: Event) => {
        if (!toggled)
            return;

        hideToolTip();
        setToggled(false);
    };
    
    return (
        <Container>
            <ButtonContainer>
                <ToggleMenuButton 
                    id='toggle-menu-button'
                    toggled={toggled} 
                    onClick={clickHandler}
                    onMouseEnter={showToolTip}
                    onMouseLeave={hideToolTip}
                >
                    {user?.currentAdvertiser.name.charAt(0).toUpperCase()}
                </ToggleMenuButton>
                <ButtonToolTip 
                    toggled={toggled}
                    toggleToolTip={toggleToolTip}
                >
                    Gerenciar anunciantes
                </ButtonToolTip>
            </ButtonContainer>
            <AvailableAdvertisersMenu 
                toggled={toggled}
                handleClickOutside={hideMenu}
                email={user?.email}
                currentAdvertiser={user?.currentAdvertiser}
                availableAdvertisers={user?.availableAdvertisers}
            />
        </Container>
    );
}

export default CurrentAdvertiserButton;
