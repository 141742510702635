import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';

import AdvertiserOption from '../AdvertiserOption';

import { LoginHttpClient } from '../../../services/api/platform-api/login-http-client';

import { AdvertiserInfo } from '../../../interfaces/Advertisers/AdvertiserInfo';

import { Container, Header, Main, Footer } from './styles';


interface AvailableAdvertiserMenuProps {
    toggled: boolean,
    handleClickOutside: (e: Event) => void,
    email?: string,
    currentAdvertiser?: AdvertiserInfo,
    availableAdvertisers?: AdvertiserInfo[]
}


function AvailableAdvertiserMenu(props: AvailableAdvertiserMenuProps) {
    const ref = useDetectClickOutside({ onTriggered: props.handleClickOutside })
    const history = useHistory();

    const handleLogOut = async () => {
        await new LoginHttpClient().logout();
        history.push('/');
    }

    return (
        <Container ref={ref} toggled={props.toggled} className='card-shadow'>
            <Header>
                ANUNCIANTES
            </Header>
            <Main showScroll={
                (props.availableAdvertisers && props.availableAdvertisers.length >= 3)? true : false
            }>
                {
                    props.availableAdvertisers?.map((value, index) => {
                        return <AdvertiserOption
                            key={index}
                            email={props.email}
                            advertiserInfo={value}
                            isCurrent={value.id === props.currentAdvertiser?.id? true : false }
                        />
                    })
                }
            </Main>
            <Footer onClick={ () => handleLogOut() }>
                <div>Sair de todas as contas</div>
            </Footer>
        </Container>
    );
}

export default AvailableAdvertiserMenu;
