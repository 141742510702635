import {PlatformAPIHttpClient} from "./platform-api-http-client";

export class CreativesHttpClient extends PlatformAPIHttpClient {
    public uploadCreative = (file:any): Promise<any> => {
        const formData = new FormData();
        formData.append('file', file);
        return this.instance.post('/creatives/upload', formData)
    };

    public requestCreatives = (advertiserId: number): Promise<any> => {
        return this.instance.post('/creatives/request', {
            advertiser_id: advertiserId
        });
    }
}