import styled from "styled-components";


export const Container = styled.div`
    td {
      color: #96A0A7 !important;
    }

    th:first-child {
      max-width: 100px;
      padding-right: 150px;
    }
    
    .bar {
      height: 8px;
      width: 90px;
      border: 1px solid transparent;
      border-radius: 3px;
      background: #EEEEEE;
      border-radius: 20px;
    }
    
    .bar-inner {
      height: 8px;
      border-radius: 3px;
      background-color: #00c366;
      margin-top: -1px;
    }
`;
