import styled from "styled-components";

import { ReactComponent as SelectedIcon } from "../../../assets/selected_icon.svg";

export const Container = styled.div<{ toggled: boolean }>`
  position: absolute;

  display: ${(props) => (props.toggled ? "block" : "none")};

  width: 216px;
  height: 99px;

  margin-top: 125px;
  margin-left: 208px;
  padding: 6px;

  border-radius: 6px;

  z-index: 99999;
`;

export const StyledSelectedIcon = styled(SelectedIcon)`
  margin-left: 35px;
`;

export const RoleOption = styled.div`
  display: flex;
  align-items: center;

  width: 204px;
  height: 50px;

  background: #f1f1f1;
  border-radius: 4px;

  .role-info-container {
    padding-left: 9px;

    font-family: "Mulish";
    font-style: normal;

    color: #444242;

    .role-name {
      font-weight: 400;
      font-size: 14px;
    }

    .role-description {
      font-weight: 300;
      font-size: 11px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;

  padding: 8px 81px 8px 9px;

  border-radius: 4px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #ff5656;

  &:hover {
    cursor: pointer;
    user-select: none;
    background: #f1f1f1;
  }
`;
