import Button from "../../../../Generic/Button";
import { Container } from "./styles";
import { useState, useContext } from "react";
import { ToastInvokerProps } from "../../../../../interfaces/Toast/IToastInterfaces";
import { CreativesHttpClient } from "../../../../../services/api/platform-api/creatives-http-client";
import { UserContext } from "../../../../../context/UserContext";

const RequestCreatives: React.FC<ToastInvokerProps> = ({ setToastList }: ToastInvokerProps) => {
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext)

    const handleButtonClick = async () => {
        setLoading(true);
        
        try {
            await new CreativesHttpClient().requestCreatives(user!.currentAdvertiser.id);

            setToastList((toastList) => [...toastList, {
                type: 'success',
                message: 'Solicitação notificada com sucesso!\nAguarde o contato do nosso time através do seu e-mail para seguirmos o processo.'
            }]);
        } catch (error) {
            setToastList((toastList) => [...toastList, {
                type: 'error',
                message: 'Ocorreu um erro inesperado, tente novamente mais tarde.'
            }]);
        }

        setLoading(false);
    }

    return (
        <Container loading={loading}>
            <span>Abrir solicitação de anúncios</span>
            <p>Use o botão abaixo para notificar o nosso time e entraremos em contato.</p>
            <Button loading={loading} execute={handleButtonClick}>
                Solicitar produção de anúncios
            </Button>
        </Container>
    );
}

export default RequestCreatives;