import { useHistory } from 'react-router-dom';
import { Container, CreateCampaignData } from './styles';

function CreateCampaignDisplay() {
    const history = useHistory();

    const handleClick = () => {
        history.push('/create-campaigns');
    }

    return (
        <Container className='card-shadow'>
            <div className='title-div'>
                <h2>CAMPANHAS</h2>
            </div>

            <CreateCampaignData>
                <p>Comece sua experiência com a gente!</p>
                <button onClick={handleClick}>+ Iniciar minha primeira campanha</button>
            </CreateCampaignData>
        </Container>
    );
}


export default CreateCampaignDisplay;