import styled from "styled-components";


export const Container = styled.div`
    margin-top: 60px;
    max-width: 45.4375rem;
  
    h2 {
        font-weight: bold;
        font-size: 1.125rem;
        color: #444242;
    }

    .form-inputs {
        display: flex;
        align-items: center;

        padding: 0 3.75rem 3.75rem;
    }

    .first-input, .second-input {
        flex: 1;
        margin-top: 1.875rem;

        & > span {
            margin-bottom: .5rem;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .second-input {
        margin-left: 2.5rem;
    }

    #divisor {
        display: flex;
        align-items: center;
        #gray-line {
            flex: 5;
            height: 1px;
            background: #EBEBEB;
        }

        #blue-contrast {
            flex: 1;
            height: 6px;
            background: #004993;
        }
    }
    
    .help-span {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        color: #444242;

        display: flex;
        align-items: center;

        margin-right: 20px;
        
        svg {
            margin-left: 4px;
            color: #FFF;
            fill: #8E8E8E;
        }
    }
`;
