import {Redirect, Route, RouteProps} from "react-router-dom";
import React, {useState} from "react";
import AppRoute from "./AppRoute";
import Onboarding from "../../components/Onboarding";
import SkeletonPage from "../../components/Generic/SkeletonPage";

interface PrivateRouteProps extends RouteProps {}

const PrivateRoute: React.FC<PrivateRouteProps> = ({...rest}) => {
    const [isLoading, setLoading] = useState(true);
    const [isAuthenticated, setAuth] = useState(false);

    AppRoute({setLoading, setAuth});

    if(isAuthenticated && !isLoading)
        return (
            <>
                <Route {...rest} />
                <Onboarding />
            </>
        )
    else if(!isAuthenticated && !isLoading)
        return <Redirect to='/' />
    else
        return <SkeletonPage />;
}

export default PrivateRoute;