import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 60px;

    .title-div {
        height: 75px;
        border-bottom: 1px solid #EBEBEB;

        display: flex;
        justify-content: space-between;
        align-items: center;
        
        button {
            padding: 0 !important;
            width: 12.5625rem;
            height: 2.3125rem;
            margin-right: 3.75rem;
            text-align: center;
            vertical-align: middle;
        }
    }

    h2 {
        font-weight: bold;
        font-size: 16px;
        color: #006DAC;
        height: 75px;
        max-width: 230px;
        border-bottom: 1px solid #006DAC;
        padding: 28px 70px;
        margin 0 !important;

    }
`;

export const CampaignData = styled.div`
    table {
        border-collapse: collapse;

        margin-top: 20px;
        width: 100%;

        tr {
            padding-left: 60px;

            th, td {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #585858;
            }
            
            th {
                text-align: start;
                
                padding-bottom: 10px;
                
            }
            
            td {
                padding: 20px 0;
                background: #F8F8F8;

                svg {
                    margin-right: 10px;
                }
            }
            
            td:first-child, th:first-child {
                padding-left:60px;
            }
        }
    }
`;