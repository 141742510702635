import React from 'react';

import logoImg from '../../../assets/ECBR_Ads.png';


import { Container } from './styles';


const SkeletonHeader: React.FC = () => {
    return (
        <Container className='shadow-header'>
            <img id='logo' src={logoImg} alt="ECBR Ads Logo" />
            <div id='title'></div>
            <div id="buttons">
                <div className='skeleton-button'></div>
                <div className='skeleton-button'></div>
            </div>
        </Container>
    );
}

export default SkeletonHeader;
