import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;

    z-index: 999;
    background: rgba(0, 0, 0, 0.6);

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    div {
        background: #FFF;

        max-width: 625px;
        border-radius: 10px;

        h1 {
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: #585858;

            padding: 30px 39px;

            border-bottom: 1px solid #CFCFCF;
        }

        .description {
            padding: 30px 39px;

            .first-step {
                display: block;
                font-weight: bold;
                color: #004993;
                font-size: 14px;
            }

            .italic {
                font-style: italic;
            }

            .form-inputs {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;

                div {
                    flex: 1;
                    .label {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 18px;
                        color: #444242;
                        display: inline-block;
                        margin-bottom: 10px;

                        span {
                            font-weight: normal;
                            font-style: italic;
                        }
                    }

                    div {
                        border-radius: 0;
                    }

                    &+div {
                        margin-left: 42px;
                    }
                }
            }
        }

        .options {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;

            .skip, .save {
                border: none;

                &:hover {
                    cursor: pointer;
                }

                &:focus {
                    outline: none;
                }
            }

            .skip {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #004993;
                background: transparent;
                margin-right: 40px;

                &:hover {
                    color: #748296;
                }
            }

            .save {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #FFFFFF;

                padding: 10px 30px;
                background: #004993;
                border: 1px solid #004993;
                border-radius: 4px;

                &:disabled {
                    background: rgba(0, 73, 147, 0.8);
                    border: 1px solid rgba(0, 73, 147, 0.8);
                    cursor: not-allowed;
                }

                &:hover:not(:disabled) {
                    background: #F6F7F9;
                    border: 1px solid #CDCDCD;
                    color: #004993;
                }
            }
        }

        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.011em;
            color: #444242;
        }
    }
`;