import React from 'react';
import NumberFormat from 'react-number-format';
import {GeneralContainer} from './styles';


interface IAudienceStatisticsDetail {
    imageSrc: string;
    audienceTitle: string;
    audienceNumber: number;
    paddingLeft: string;
}


const AudienceStatisticsDetail: React.FC<IAudienceStatisticsDetail> = ({ 
    imageSrc, 
    audienceTitle, 
    audienceNumber,
    paddingLeft
}) => {
    return (
        <GeneralContainer paddingLeft={paddingLeft}>
            <div className='detail-title'>
                <img src={imageSrc} />
                <span>{audienceTitle}</span><br />
            </div>
            <div className='detail-value'>
                <h4>
                    <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','}
                                  value={audienceNumber} />
                </h4>
            </div>
        </GeneralContainer>
    );
}

export default AudienceStatisticsDetail;
