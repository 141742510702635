import styled from "styled-components";
import leftBgImg from "../../assets/first-access/left-bg.png";

export const BaseContainer = styled.div`
  display: flex;
  background: #FFF;
  & {
    height: 100%;
    
    footer {
      color: #9C9C9C;
      font-family: 'Sarabun';
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
    }
  }
`;

export const LeftImageContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 48%;
  background: url(${leftBgImg});
  text-align: center;
`;

export const LeftImageContent = styled.div`
  width: 550px;
  margin: 0 auto;
  & {
    
    h1 {
      color: #FFF;
      font-family: 'Work Sans', sans-serif;
      font-size: 45px;
      line-height: 53px;
      margin-top: 36px;
    }
    
    p {
      color: #F5F5F5;
      margin-top: 17px;
      line-height: 20px;
    }
  }
`;

export const RightPasswordContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 52%;
`;

export const RightPasswordContent = styled.div`
  
`;

export const RightPasswordForm = styled.div`
  margin: 0 auto;
  width: 400px;
    & {
        h4, label {
            color: #444242
        }
    
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    
        h4, p {
            text-align: center;
        }
    
        h4 {
            font-size: 20px;
            font-weight: 700;
            margin-top: 51px;
        }
    
        p {
            margin: 5px 0 20px 0;
            color: #96A0A7;
            font-size: 14px;
        }
    }
`;

export const NameInputsContainer = styled.div`
  margin: 0 auto;
  
  & {
    width: 300px;
    
    label {
      font-weight: 700;
      font-size: 14px;
    }

    input {
      border: 0.75px solid #BFBFBF;
      display: block;
      height: 47px;
      margin: 7px 0 10px 0;
      outline: none;
      width: 150px;
      padding-left: 20px;
    }
    
    .success-input {
      color: #BFBFBF;
    }
    
    .top-input {
      margin-bottom: -5px;
    }
    
    .input-error {
      border: 0.75px solid #FF5656;
    }
  }

`;

export const RightPasswordInputsContainer = styled.div`
  margin: 0 auto;
  
  & {
    margin-left: 41px;
    
    label {
      font-weight: 700;
      font-size: 14px;
    }

    input {
      border: 0.75px solid #BFBFBF;
      display: block;
      height: 47px;
      margin: 7px 0 10px 0;
      outline: none;
      width: 330px;
      padding-left: 20px;
    }
    
    .success-input {
      color: #BFBFBF;
    }
    
    .top-input {
      margin-bottom: -5px;
    }
    
    .input-error {
      border: 0.75px solid #FF5656;
    }
    
    img {
      cursor: pointer;
      position: relative;
      top: 59px;
      left: 125px;
      width: 21px;
      height: 17px;
    }
  }
  
`;

export const RightPasswordRequirementsContainer = styled.div`
  display: flex;
  width: 340px;
  justify-content: space-evenly;
  margin: 0 auto;
  
  & {
    margin-left: 42px;
    span {
      font-size: 10px;
      font-weight: 700;
      margin-right: 13px;
    }

    .pass-req-success {
      color: #1BCC62
    }
  }
`;

export const SaveButton = styled.div`
  display: flex;
  justify-content: center;
  
  & {
    button {
      width: 108px;
      height: 42px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #FFF;
      background: #004993;
      border: none;
      border-radius: 3px;
      margin-top: 30px;
      justify-content: center;
      cursor: pointer;
      font-family: 'Mulish';
    }
    
    button:hover {
      background: #F6F7F9;
      border: 1px solid #CDCDCD;
      color: #004993;
    }
    
    button:disabled {
      background: #F6F7F9;
      border: 1px solid #CBCBCB;
      color: #CBCBCB;
      cursor: not-allowed;
    }
  }
`;

export const ErrorBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const ErrorBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  color: #FFF;
  font-size: 14px;
  text-align: center;
  background: #FF5656;
  height: 60px;
`;

export const SuccessBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 271px;
  color: #FFF;
  font-size: 14px;
  text-align: center;
  background: #1BCC62;
  
  a, a:hover {
    color: #004993;
  }
`;