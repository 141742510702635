import {ICampaignCreativesInfo} from "../../interfaces/Creatives/ICampaignCreativesInfo";
import {ImpressionsByCreativeData} from "../../interfaces/CampaignReport/Creatives/ImpressionsByCreativeData";

export default function attachCreativeImpsToCreativeList(creatives: ICampaignCreativesInfo[], impsByCreative: ImpressionsByCreativeData[]): ICampaignCreativesInfo[] {
    creatives.forEach(function (creative:ICampaignCreativesInfo, index:any) {
        const matchingCreativeData = impsByCreative.find((creativeData) => {
            return creativeData.creative_id === creative.id;
        });

        creatives[index].impressions = matchingCreativeData?.impressions || 0;
    });

    return creatives;
}