import styled from "styled-components";


export const GeneralContainer = styled.div`
  width: 245px;
  height: 121px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px #D1D9E7;
  border-radius: 10px;
  color: #444242;

  & {
    h5 {
      font-size: 14px;
      font-weight: 700;
      padding: 20px 0 0 26px;
    }
    
    .data-values {
      display: flex;
      
      img {
        margin: 12px 7px 0 26px;
        width: 23px;
        height: 23px;
      }
      
      h4 {
        margin: 5px 0 12px 3px;
        font-size: 30px;
        font-weight: 700;
      }
    }

    #divisor {
      display: flex;
      align-items: center;

      #gray-line {
        flex: 5;
        height: 1px;
        background: #EBEBEB;
      }

      #blue-contrast {
        flex: 17;
        height: 6px;
        background: #004993;
      }
    }
  }
`;
