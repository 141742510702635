import { useState, ChangeEvent } from "react";
import { Container, SendFiles, Guide } from "./styles";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CreativesHttpClient } from "../../../../../services/api/platform-api/creatives-http-client";
import axios from "axios";
import { ToastInvokerProps } from "../../../../../interfaces/Toast/IToastInterfaces";

const AttachCreatives: React.FC<ToastInvokerProps> = ({ setToastList }: ToastInvokerProps) => {
    const [loading, setLoading] = useState(false);
    const [labelDisabled, setLabelDisabled] = useState(false);

    const handleSubmitImage = async (event: ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        try {
            const file = event.target.files?.[0];
            const extension = file?.name.split('.').pop();
    
            if(extension !== 'zip')
                throw new Error();
    
            await new CreativesHttpClient().uploadCreative(file);

            setLabelDisabled(true);
            setToastList((toastList) => [...toastList, {
                type: 'success',
                message: 'Arquivo enviado com sucesso!\nPara enviar um novo, basta refazer o processo.'
            }]);
        } catch (error) {
            if(axios.isAxiosError(error)) {
                setToastList((toastList) => [...toastList, {
                    type: 'error',
                    message: 'Desculpe, não identificamos nenhuma imagem no arquivo enviado.'
                }]);
            } else {
                setToastList((toastList) => [...toastList, {
                    type: 'error',
                    message: 'Desculpe, ainda não permitimos arquivos diferentes de .zip'
                }]);
            }
        }
        setLoading(false);
    }

    return (
        <Container>
            <SendFiles labelDisabled={labelDisabled} loading={loading}>
                <label htmlFor="creative-input">
                    {loading ? 
                        (
                            < AiOutlineLoading3Quarters />
                        )
                        : 
                        'Enviar arquivo compactado'
                    }
                </label>
                <input 
                    id="creative-input" 
                    type="file" 
                    accept=".zip"
                    onChange={handleSubmitImage} 
                />
                <span className="zip-info">Permitimos apenas arquivos .zip</span>
            </SendFiles>
            <Guide>
                <span>Guia para produção de anúncios</span>
                <a target="__blank" href="https://white-label-cdn-ads.voxus.tv/s2/ecbr-ads/Formatos_Banners_ECBRAds.pdf">Download PDF - Anúncios ECBRAds</a>
            </Guide>
        </Container>
    );
}

export default AttachCreatives;