import styled from 'styled-components';

export const Header  = styled.div`
    padding: 26px 0 26px 65px;

    h1 {
        color: #444242;
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
    }
`;

export const Container = styled.div`
    padding-top: 3.75rem;
    max-width: 45.4375rem;
    margin: 0 auto;
    button {
      margin: 40px 0 114px;
    }
`;

export const Errors = styled.div`
    position: relative;

    .error-box div {
        position: fixed;
        bottom: 80px;
        left: 60px;
        width: 521px;

        button {
            margin: 0;
        }
    }
`
