import { AxiosError, AxiosResponse } from "axios";

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { CampaignReportHttpClient } from "../../services/api/data-api/campaign-report-http-client";

import { 
    Container, 
    AlertContainer, 
    AlertHeader, 
    AlertContent, 
    AlertFooter, 
    AlertButton,
    UploadContainer,
    UploadHeader,
    UploadContent,
    UploadFooter,
    UploadButton
} from "./styles";


const ReportDataIngestion: React.FC = () => {
    const history = useHistory();
    const { user } = useContext(UserContext);

    const [file, setFile] = useState<File | null | undefined>(null);

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFile(event.target.files?.[0]);
    };

    const handleFileSubmition = () => {
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        new CampaignReportHttpClient().postReportData(formData)
        .then((response: AxiosResponse) => {
            if (response.status !== 200) {
                alert("Algo inesperado aconteceu. Tente novamente mais tarde!");
                return;
            }

            alert("Arquivo enviado com sucesso!");
        })
        .catch((error: AxiosError) => {
            if (error.response?.status) {
                alert("Arquivo inválido enviado, tente novamente!");
                return;
            }
            alert("Algo inesperado aconteceu. Tente novamente mais tarde!");
        });
    }

    return (
        <Container>
            {
                user?.userType === "super_admin" ?
                    <UploadContainer className="card-shadow">
                        <UploadHeader>
                            Escolha um arquivo
                        </UploadHeader>
                        <UploadContent>
                            <input type="file" name="" id="" onChange={handleFileInputChange} />
                        </UploadContent>
                        <UploadFooter>
                            <UploadButton onClick={handleFileSubmition}>
                                Enviar
                            </UploadButton>
                        </UploadFooter>
                    </UploadContainer>
                :
                    <AlertContainer className="card-shadow">
                        <AlertHeader>
                            Aviso!!!
                        </AlertHeader>
                        <AlertContent>
                            Você está acessando uma página à qual não possui acesso.
                        </AlertContent>
                        <AlertFooter>
                            <AlertButton onClick={() => history.push('/list-campaigns')}>
                                Voltar
                            </AlertButton>
                        </AlertFooter>
                    </AlertContainer>
            }
        </Container>
    )
};

export default ReportDataIngestion;
