import styled, { css } from "styled-components";

interface SubmitButtonProps {
    loading?: boolean;
}

export const SubmitButton = styled.button<SubmitButtonProps>`
    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    padding: 14px 28px;
    
    background: #004993;
    border: none;
    border: 1px solid #004993;
    border-radius: 4px;

    font-weight: 600;
    color: #FFF;

    transition: all ease 0.2s;

    ${props => props.loading && css`
        #loading-icon {
            animation: loading 1s linear infinite;
        }
    `};

    &:disabled {
        background: rgba(0, 73, 147, 0.8);
        border: 1px solid rgba(0, 73, 147, 0.8);
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background: #F6F7F9;
        color: #004993;
        cursor: pointer;
        border: 1px solid #CDCDCD;
    }

    &:focus {
        outline: none;
    }
`;