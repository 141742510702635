import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  height: 54px;
`;

export const UserIcon = styled.div<{ opacity: number }>`
  width: 32px;
  height: 32px;

  margin-left: 23px;

  background: rgba(211, 211, 211, ${(props) => props.opacity});
  border-radius: 4.15313px;
`;

export const UserInfoContainer = styled.div<{ opacity: number }>`
  margin-left: 10px;

  .name-container {
    width: 118px;
    height: 13px;

    background: rgba(217, 217, 217, ${(props) => props.opacity});
    border-radius: 10px;
  }

  .email-container {
    width: 184px;
    height: 13px;

    margin-top: 5px;

    background: rgba(236, 236, 236, ${(props) => props.opacity});
    border-radius: 10px;
  }
`;

export const RoleInfoContainer = styled.div<{ opacity: number }>`
  width: 100px;
  height: 13px;

  margin-left: 69px;

  background: rgba(217, 217, 217, ${(props) => props.opacity});
  border-radius: 10px;
`;
