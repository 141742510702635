import React, { ReactNode, useCallback } from "react";
import { useNewCampaignValues } from "../../../../pages/NewCampaign/NewCampaignContext";
import Button from '../../../Generic/Button';
import { Container } from './styles';
import {CampaignsHttpClient} from "../../../../services/api/platform-api/campaigns-http-client";
import {useHistory} from "react-router-dom";
import { ToastInvokerProps, ToastItemProps } from "../../../../interfaces/Toast/IToastInterfaces";
import { isValidUrl } from "../../../../services/utils/formValidators";

interface NewCampaignButtonProps extends ToastInvokerProps {
    setShowModal(value: boolean): void;
    setCampaignId(value: number | null): void;
    setCreatingCampaign(value: boolean): void;
    creatingCampaign: boolean;
    children: ReactNode;
}

function NewCampaignButton({ children, setShowModal, setCampaignId, setToastList, setCreatingCampaign, creatingCampaign }: NewCampaignButtonProps) {
  const history = useHistory();

  const { 
    campaignName,
    campaignDuration,
    campaignStartDate,
    clickUrl,
    keyword1, 
    keyword2, 
    keyword3, 
    keyword4, 
    budget, 
    cpmMax, 
    categoriesIDs,
    setCategoriesAndKeywordsAreEmpty,
    setCampaignNameIsEmpty,
    setBudgetIsEmpty,
    setCpmMaxIsEmpty,
    setCampaignStartDateIsEmpty,
    setCampaignDurationIsEmpty,
    setClickUrlIsInvalid
     
  } = useNewCampaignValues();

  const AllKeywordsAreEmpty = (keyword1 === '' && keyword2 === '' && keyword3 === '' && keyword4 === '');

  const executeCreateCampaignRequest = useCallback(() => {
    new CampaignsHttpClient().createCampaign({
        name: campaignName,
        segmentBudget: budget,
        segmentMaxCPM: cpmMax,
        segmentKeywords: [keyword1, keyword2, keyword3, keyword4],
        segmentCategories: categoriesIDs,
        redirectUrl: clickUrl,
        initDate: campaignStartDate!.toISOString().split('T')[0],
        durationInDays: campaignDuration,
      }).then(response => {
        setCampaignId(response.data.data.id);
        setShowModal(true);
        setCreatingCampaign(false);
      }).catch(() => {history.push('/create-campaigns')});
  },  
  [
    history, 
    campaignName,
    campaignDuration,
    campaignStartDate,
    clickUrl,
    keyword1, 
    keyword2, 
    keyword3, 
    keyword4, 
    budget, 
    cpmMax, 
    categoriesIDs, 
    setShowModal,
    setCampaignId,
    setCreatingCampaign
  ]);

  const handleFormSubmit = useCallback(() => {
    setCreatingCampaign(true);

    let hasError = false;

    const errorMessages: ToastItemProps[] = [
      {
        message: 'Encontramos um ou mais campos sem preenchimento, apenas a opção de Keywords não é obrigatória, todos os demais campos devem ser preenchidos e ao menos 1 categoria selecionada.',
        type: 'error'
      }
    ];

    if (categoriesIDs.length === 0 && AllKeywordsAreEmpty) {
      setCategoriesAndKeywordsAreEmpty(true);
      hasError = true;
    } else {
      setCategoriesAndKeywordsAreEmpty(false);
    }

    if(campaignName === '') {
      setCampaignNameIsEmpty(true);
      hasError = true;
    } else {
      setCampaignNameIsEmpty(false);
    }

    if(!campaignStartDate) {
      setCampaignStartDateIsEmpty(true);
      hasError = true;
    }

    if(!campaignDuration) {
      setCampaignDurationIsEmpty(true);
      hasError = true;
    }

    if(!clickUrl || !isValidUrl(clickUrl)) {
      setClickUrlIsInvalid(true);
      errorMessages.unshift({
        message: 'Garanta que a URL de clique da campanha esteja preenchida com http:// ou https:// no início.',
        type: 'error'
      });
      hasError = true;
    }

    if(budget < 0.01) {
      setBudgetIsEmpty(true);
      hasError = true;
    } else {
      setBudgetIsEmpty(false);
    }

    if(cpmMax < 0.01) {
      setCpmMaxIsEmpty(true);
      hasError = true;
    } else {
      setCpmMaxIsEmpty(false);
    }

    if(hasError) {
      setCreatingCampaign(false);
      setToastList(errorMessages);
    } else {
      executeCreateCampaignRequest();
    }
  }, [
    setCreatingCampaign, 
    categoriesIDs.length, 
    AllKeywordsAreEmpty, 
    campaignName, 
    campaignStartDate, 
    campaignDuration, 
    clickUrl, 
    budget, 
    cpmMax, 
    setCategoriesAndKeywordsAreEmpty, 
    setCampaignNameIsEmpty, 
    setCampaignStartDateIsEmpty, 
    setCampaignDurationIsEmpty, 
    setClickUrlIsInvalid, 
    setBudgetIsEmpty, 
    setCpmMaxIsEmpty, 
    setToastList, 
    executeCreateCampaignRequest
  ]);

  return(
    <Container>
      <Button execute={handleFormSubmit} loading={creatingCampaign}>
          {children}
      </Button>
    </Container>
  );
}

export default NewCampaignButton;