import { useState } from 'react';
import { Container, Controller, SendInfo } from './styles';
import { TextInput } from '../../../Generic/FormElements/TextInput';
import Button from '../../../Generic/Button';
import { isValidEmail, isNotEmptyString } from '../../../../services/utils/formValidators';
import OutsideClickToggleContainer from '../../../Generic/OutsideClickToggleContainer';
import { CampaignsHttpClient } from '../../../../services/api/platform-api/campaigns-http-client';
import Toast from '../../../Generic/Toast';
import { CampaignDetailData } from '../../../../interfaces/Campaigns/CampaignDetails/CampaignDetailData';
import { ToastItemProps } from '../../../../interfaces/Toast/IToastInterfaces';

interface InvestmentTermsCardProps {
    showCard: boolean;
    setShowCard: React.Dispatch<React.SetStateAction<boolean>>;
    campaigns: CampaignDetailData[];
}

const InvestmentTermsCard: React.FC<InvestmentTermsCardProps> = ({ showCard, setShowCard, campaigns }: InvestmentTermsCardProps) => {
    const [selectedOption, setSelectedOption] = useState(1);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [toastList, setToastList] = useState<ToastItemProps[]>([]);

    const handleButtonClick = async () => {
        setLoading(true);
        try {

            await Promise.all(campaigns.map(campaign => {
                if(!!campaign.campaign_pi_signature) return;

                return new CampaignsHttpClient().sendPiData({
                    campaignId: campaign.id,
                    name,
                    email
                });
            }));
    
            renderToastMessage('success');
            setShowCard(false);
        } catch (error) {
            renderToastMessage('error');
        }
        setLoading(false);
    }

    const renderToastMessage = (type: 'error' | 'success') => {
        const messages = {
            error: 'Ocorreu um erro inesperado. Tente novamente em breve',
            success: 'Os dados para concluir os termos foram salvos! Vamos enviar o documento em breve para o e-mail informado.'
        }

        setToastList((toastList) => [...toastList,
            {
                message: messages[type],
                type
            }
        ]);
    }

    return (
        <>
            <Container showCard={showCard} >
                <OutsideClickToggleContainer show={showCard} setShow={setShowCard}>
                    <Controller selectedOption={selectedOption}>
                        <ul>
                            <li onClick={() => setSelectedOption(1)}>
                                <div>
                                    Termos de Investimento
                                </div>
                            </li>
                        </ul>
                    </Controller>

                    <SendInfo loading={loading}>
                        <span>
                            Dados para concluir a assinatura
                        </span>
                        <p>
                            Precisamos de duas informações para enviar os termos para assinatura do responsável.
                        </p>
                        <div className="form-inputs">
                            <span>Nome completo</span>
                            <TextInput value={name} setValue={setName} validate={isNotEmptyString} />
                        </div>

                        <div className="form-inputs">
                            <span>E-mail</span>
                            <TextInput value={email} setValue={setEmail} validate={isValidEmail} />
                        </div>

                        <Button loading={loading} disabled={!isValidEmail(email) || !isNotEmptyString(name)} execute={handleButtonClick}>
                            Enviar termos de investimento
                        </Button>
                    </SendInfo>
                </OutsideClickToggleContainer>
            </Container>

            <Toast position='bottom-left' toastList={toastList} />
        </>
    );
}

export default InvestmentTermsCard;