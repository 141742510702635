import {ImpressionsBySegmentData} from "../../interfaces/CampaignReport/Segments/ImpressionsBySegmentData";
import {PercentageTableListObject} from "../../components/PercentageTableList/interface/PercentageTableListObject";

export default function parseBestSegmentsToPercentObject(impsBySegment: ImpressionsBySegmentData[], totalImps: number):PercentageTableListObject[] {
    let bestSegments:PercentageTableListObject[] = [];

    impsBySegment.forEach(function (item:any) {
        let segmentPercentage = (100 * item.impressions) / totalImps;

        if(segmentPercentage >= 40)
            segmentPercentage = 100;
        else if(segmentPercentage >= 10)
            segmentPercentage = 40;
        else
            segmentPercentage = 10;

        bestSegments.push({
            'name': item.segment_name,
            'percentage': segmentPercentage
        });
    });

    return bestSegments;
}