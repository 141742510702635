import React, { useState, useEffect } from "react";
import { AxiosResponse } from "axios";

import { AdvertisersHttpClient } from "../../../services/api/platform-api/advertisers-http-client";

import UserManagementMenu from "../UserManagementMenu";

import {
    Container,
    ButtonToolTip,
    ButtonContainer,
    StyledUsersIcon,
    ToggleMenuButton,
} from "./styles";


function ManageUsersButton() {
    const [toggled, setToggled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usersList, setUsersList] = useState<any[]>([]);

    let toolTipTimeout: NodeJS.Timeout;
    const [toolTipToggled, setToolTipToggled] = useState(false);

    const toggleButtonClickHandler = () => {
        setToggled(prevState => !prevState);
    };

    const showToolTip = () => {
        toolTipTimeout = setTimeout(() => {
            setToolTipToggled(true);
        }, 400);
    };

    const hideToolTip = () => {
        clearInterval(toolTipTimeout);
        setToolTipToggled(false);
    };

    const hideMenu = (e: Event) => {
        if (!toggled)
            return;

        hideToolTip();
        setToggled(false);
        setUsersList([]);
    };

    const removeUser = (userId?: number) => {
        const filteredUsersList = usersList.filter(userItem => {
            return userItem.id !== userId
        });

        setUsersList(filteredUsersList);
    };

    useEffect(() => {
        if (!toggled)
            return;

        setLoading(true);
        new AdvertisersHttpClient().getAdvertiserUsers()
            .then((response: AxiosResponse) => {
                if (response.status !== 200) {
                    alert('Algo inesperado aconteceu. Tente novamente mais tarde!');
                    return;
                }

                setUsersList(response.data.data);
                setLoading(false);
            }).catch(() => {
                alert('Algo inesperado aconteceu. Tente novamente mais tarde!');
            });
    }, [toggled]);

    return (
        <Container>
            <ButtonContainer>
                <ToggleMenuButton
                    toggled={toggled}
                    onClick={toggleButtonClickHandler}
                    onMouseEnter={showToolTip}
                    onMouseLeave={hideToolTip}
                >
                    <StyledUsersIcon />
                </ToggleMenuButton>
                <ButtonToolTip
                    menuToggled={toggled}
                    toolTipToggled={toolTipToggled}
                >
                    Gerenciar usuários
                </ButtonToolTip>
            </ButtonContainer>
            <UserManagementMenu
                toggled={toggled}
                loading={loading}
                userList={usersList}
                handleClickOutside={hideMenu}
                removeUserHandler={removeUser}
            />
        </Container>
    );
}

export default ManageUsersButton;
