import { useEffect, useRef } from "react";
import { Container } from "./styles";

interface OutsideClickToggleContainerProps {
    show: boolean;
    setShow(state: boolean): void;
    children: React.ReactNode;
}

const OutsideClickToggleContainer: React.FC<OutsideClickToggleContainerProps> = ({ show, setShow, children }: OutsideClickToggleContainerProps) => {
    const node = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = (e: MouseEvent) => {
        if (node.current?.contains(e.target as Node)) return;
        setShow(false);
    };

    return (
        <Container show={show} ref={node}>
            {children}
        </Container>
    );
}

export default OutsideClickToggleContainer;