import styled from "styled-components";

import { ReactComponent as UsersIcon } from "../../../assets/audience-details/users_icon.svg";

export const Container = styled.div`
  position: absolute;

  margin-left: 85%;
`;

export const ButtonContainer = styled.div``;

export const ToggleMenuButton = styled.button<{ toggled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border: none;
  background-color: white;
  border-radius: 3.3225px;

  svg {
    ${(props) =>
      props.toggled &&
      `
       fill: #004993;
       stroke: #004993;
    `};
  }

  &:hover {
    background-color: #dcecfd;

    svg {
      fill: #004993;
      stroke: #004993;
    }
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

export const ButtonToolTip = styled.div<{
  toolTipToggled: boolean;
  menuToggled: boolean;
}>`
  display: ${(props) =>
    props.toolTipToggled && !props.menuToggled ? "flex" : "none"};
  justify-content: center;
  align-items: center;

  position: fixed;

  width: 133px;
  height: 28px;
  padding: 5px 12px 5px 12px;

  margin-top: 4px;
  margin-left: -3.9%;

  background: #444242;
  border-radius: 5px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  color: white;

  z-index: 99999;
`;

export const StyledUsersIcon = styled(UsersIcon)`
  width: 18px;
  height: 13.5px;

  fill: #d3d3d3;
  stroke: #d3d3d3;
`;
