import React from 'react';
import {PercentageTableListObject} from "./interface/PercentageTableListObject";
import { Container } from './styles';

interface PercentageTableListProps {
    objects: PercentageTableListObject[];
    titles: string[];
}

const getBackgroundColorPercentage = (percentage: number) => {
    if(percentage >= 70)
        return '#1BCC62';
    else if(percentage >= 40)
        return '#FEB344';
    else
        return 'red';
}

const PercentageTableList: React.FC<PercentageTableListProps> = ({ objects, titles}) => {
    return (
        <Container>
            <table>
                <thead>
                <tr>
                    {titles?.map(title => (
                        <th>{title}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {objects.length > 0 ? objects?.map(object => (
                    <tr>
                        <td>{object.name}</td>
                        <td>
                            <div className="bar">
                                <div className="bar-inner" style={{width: object.percentage,
                                    background: getBackgroundColorPercentage(object.percentage)}}></div>
                            </div>
                        </td>
                    </tr>
                    )) : null}
                </tbody>
            </table>
        </Container>
    );
}

export default PercentageTableList;