import React, { MutableRefObject, useRef, useState } from 'react';
import { Container } from './styles';
import { InputPrototype } from '../InputPrototype';
import InputPrototypeProps from '../../../../interfaces/Components/FormElements/InputPrototypeProps';

interface TextInputProps extends InputPrototypeProps {
    placeholder?: string;
    hasError?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({ icon, placeholder, value, setValue, validate, hasError }: TextInputProps) => {
    const ref = useRef() as MutableRefObject<HTMLInputElement>;

    const [isErrored, setIsErrored] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = () => {
        setIsFocused(false);

        if(validate)
            setIsErrored(!validate(value));
    }

    return (
        <InputPrototype
            handleClick={() => ref.current.focus()} 
            setValue={setValue} 
            value={value} 
            icon={icon} 
            isErrored={isErrored || hasError}
            setIsErrored={setIsErrored} 
            isFocused={isFocused}
            setIsFocused={setIsFocused}
        >
            <Container
                onFocus={() => setIsFocused(true)}
                onBlur={() => handleBlur()}
                ref={ref as MutableRefObject<HTMLInputElement>}
                type='text'
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </InputPrototype>
    )
}