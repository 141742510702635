import styled from "styled-components";

export const Container = styled.div`
    margin: 60px auto 0;

    width: 867px;
    height: 327px;

    background: #FFFFFF;

    @keyframes skeleton-animation {
        0% {
            background-position: -200px 0;
        }
        50% {
            background-position: calc(200px + 100%) 0;
        }
    }

    .blue-fill, .gray-fill {
        background-size: 200px 100%;
        animation: skeleton-animation 3s ease-in-out infinite;
    }

    .blue-fill {
        background-color: #004993; 
        background-image: linear-gradient(90deg, transparent, rgba(225, 231, 238, 0.5), transparent);
    }

    .gray-fill {
        background-color: #E1E7EE;
        background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
    }
`;

export const SkeletonHeader = styled.div`
    border-bottom: 1px solid #EBEBEB;

    #skeleton-title {
        padding: 30px 60px;

        max-width: 230px;

        border-bottom: 1px solid #006DAC;
        
        div {
            width: 116px;
            height: 13px;

            border-radius: 9.5px;
        }
    }
`;

export const SkeletonBody = styled.div`
    .gray-background {
        background-color: #F8F8F8;
    }

    .skeleton-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 68px 0 61px;

        height: 56px;
        border-bottom: 1px solid #EBEBEB;

        .second-column {
            display: flex;
            align-items: center;

            .skeleton-data + .skeleton-data {
                margin-left: 8px;
            }
        }

        .third-column {
            .skeleton-data {
                margin-left: 100px;
            }
        }

        & > div {
            flex: 1;
            display: flex;
            align-items: center;
        }
    }

    .skeleton-data {
        height: 14px;
        border-radius: 7px;        
    }
`