import React, { ReactNode } from "react";
import { SubmitButton } from './styles';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    execute(): void;
    loading?: boolean;
    children: ReactNode;
}

function Button({ execute, children, loading, ...buttonProps }: ButtonProps) {
    return(
        <SubmitButton  
            type="button" 
            onClick={() => execute()}
            loading={loading}
            {...buttonProps}
        >
            {loading ? 
              (
                  <AiOutlineLoading3Quarters id="loading-icon" />
              )
              : 
              children
            }
        </SubmitButton>
    );
}

export default Button;