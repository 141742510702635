import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Container } from './styles';
import { TextInput } from '../../../Generic/FormElements/TextInput';
import { isValidEmail, isNotEmptyString } from '../../../../services/utils/formValidators';
import { CampaignsHttpClient } from '../../../../services/api/platform-api/campaigns-http-client';

interface ModalProps {
    campaignId: number | null;
    closeModal(): void;
}

function ModalCampaignCreated({ closeModal, campaignId }: ModalProps) {
    const history = useHistory();
    const modalRef = useRef(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleCloseModal = useCallback(() => {
        closeModal();
        history.push('list-campaigns');
    }, [closeModal, history]);

    const handleClickOutside = (event: MouseEvent) => {
        // @ts-expect-error
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleCloseModal();
        }
    }

    const handleSaveClick = async () => {
        const campaignClient = new CampaignsHttpClient();

        try {
            if(!campaignId) throw new Error('Campaign ID not found');

            await campaignClient.sendPiData({
                campaignId: campaignId,
                name: name,
                email: email
            });

            const read = window.confirm('Os dados para concluir os termos foram salvos!\nVamos enviar o documento em breve para o e-mail informado.');

            if (read) handleCloseModal();

        } catch (error) {
            const read = window.confirm('Ocorreu um erro inesperado. Tente novamente em breve');

            if(read) handleCloseModal();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', (e) => handleClickOutside(e));

        return () => {
            document.removeEventListener('mousedown', (e) => handleClickOutside(e));
        }
    }, []);

    return (

        <Container>
            <div ref={modalRef}>
                <div className="title">
                    <h1>Configurações de campanha salvas com sucesso!</h1>
                </div>
                <div className="description">
                    <p>Faltam poucos passos para ativar sua campanha, é super simples. Precisamos de apenas duas informações para finalizar os termos de investimento da sua campanha.</p>
                    <span className="first-step">
                        1. Concluir os termos de investimento
                    </span>
                    <p>Adicione o nome completo e o e-mail da pessoa responsável para assinatura:</p>
                    <div className="form-inputs">
                        <div>
                            <span className='label'>Nome completo <span>(opcional)</span></span>
                            <TextInput setValue={setName} value={name} validate={isNotEmptyString} />
                        </div>
                        <div>
                            <span className='label'>E-mail <span>(opcional)</span></span>
                            <TextInput setValue={setEmail} value={email} validate={isValidEmail} />
                        </div>
                    </div>
                    <p className='italic'>Você pode preencher esses dados em outro momento.</p>
                    <p>Confira todas as etapas na sua tela inicial e também no seu e-mail.</p>

                    <div className="options">
                        <button onClick={handleCloseModal} className='skip'>Pular por enquanto</button>
                        <button disabled={!isNotEmptyString(name) || !isValidEmail(email)} onClick={handleSaveClick} className='save'>Salvar</button>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ModalCampaignCreated;